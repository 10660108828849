/* eslint-disable */
import ApiEndPoint from "../../../ApiEndpoints";
import Api from "../../../ApiConfig";

const baseURL = ApiEndPoint.AakashGanga;

export const getArticleDetails = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GetArticleByUserId
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const getSearchUsers = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PR_BY_SEARCH
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getPRDetails = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PR_ARTICLE
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const updatePRReport = (headers, data) => {
    const finalUrl = baseURL + ApiEndPoint.PR_REPORT
    return Api.postData(finalUrl, headers, data).catch((error) => {return error});
}

export const getPRReport = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PR_REPORT
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const reviewArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.REVIEW_ARTICLE
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const sendPrInvitation = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.SEND_PR_INVITATION
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
}
export const sendpaymentByPass = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.paymentByPass
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
}
export const appointPRRole = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.APPOINT_PR
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
}

export const recommendedArticles = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.RECOMMENDED_ARTICLES
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const getPEDetails = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PE_Article
    return Api.getData(finalUrl, headers, params);
};
export const getSocietyPEDetails = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_SOCIETY_PE_Article
    return Api.getData(finalUrl, headers, params);
};
export const getPrinviteList = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PR_Invitation
    return Api.getData(finalUrl, headers, params);
};
export const getSEArticleList = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_SE_Article
    return Api.getData(finalUrl, headers, params);
};
export const societyPayRequest = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.UPDATE_SOCIETY_PAYMENT
    return Api.postData(finalUrl, headers, params);
};
export const saveRevokeCoupon = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.SAVE_REVOKE_COUPON
    return Api.postData(finalUrl, headers, params);
};

export const acceptRejectArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.ACCEPT_REJECT_REVIEW
    return Api.postData(finalUrl, headers, params);
};

export const notAcceptedArticleReason = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.NOT_ACCEPTED_REASON
    return Api.postData(finalUrl, headers, params);
};

export const getMudras = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GETMUDRAS
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getMudrasHistory = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GETMUDRAS_HISTORY
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const getOffer = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_ALL_OFFER
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getAllGrants = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_ALL_GRANTS
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getGrantsDetails = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_GRANTS_DETAIL
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const applyForGrant = (headers, data) => {
    const finalUrl = baseURL + ApiEndPoint.APPLY_GRANT
    return Api.postData(finalUrl, headers, data).catch((error) => {return error});
}
export const getGrantsDetailsByName = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_GRANTS_DETAIL_BY_NAME
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const getLatestArticles = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_LATEST_ARTICLES
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getReadingList = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_READING_LIST
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getJournalDetail = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_JOURNAL_DETAIL
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const removeFromReadingList = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.REMOVE_FROM_READING_LIST
    return Api.postData(finalUrl, headers, {}, params).catch((error) => {return error});
};

export const getReportAfterFirstRevision = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_REPORT_AFTER_FIRST_REVISION
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const getMostViewedArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_MOST_VIEWED_ARTICLES
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const getLastBrowseArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_LAST_BROWSE_ARTICLE
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
export const addArticleToReadingList = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.ADD_ARTICLE_TO_READING_LIST
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
};
export const addLastViewArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.ADD_LAST_VIEW_ARTICLE
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
};

export const addAmendmentApi = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.ADD_AMENDMENT
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
};

export const updateAmendment = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.UPDATE_AMENDMENT
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
};

export const updateArticleRequestApi = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.UPDATE_ARTICLE_REQUEST
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
};
export const addShareAndDownloadCount = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.ADD_SHARE_AND_DOWNLOAD_COUNT
    return Api.getData(finalUrl, headers, params).catch((error) => {return error}); 
};

export const getTransactionHistory = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.Get_Payment_History_For_User
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getAppointRoleForPE = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_APPOINT_ROLE_FOR_PARTICULAR_PUBLISH_EDITOR
    return Api.getData(finalUrl, headers, params);
};
export const downloadWordArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.DOWNLOAD_ARTICLE_WORD
    return Api.getData(finalUrl, headers, params);
};

export const updateAppointRoleForPE = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.UPDATE_APPOINT_ROLE_FOR_PARTICULAR_PUBLISH_EDITOR
    return Api.postData(finalUrl, headers, params);
};

export const mudraKoshDetails = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.USER_MUDRA_DETAILS
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
};
export const getAllArticleVersion = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_ALL_ARTICLE_VERSION
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getJournalBySpecialization = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_JOURNAL_SUBJECT_SPECIALIZATION
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const getUserNotification = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_USER_NOTIFICATION
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const updateNotification = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.UPDATE_NOTIFICATION
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
}

export const searchJournalAndArticle = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_ALL_JOURNAL_ARTICLE
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
}

export const checkGrammarSpelling = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GRAMMAR_CHECK
    return Api.postData(finalUrl, headers, params).catch((error) => {return error});
}

export const getJournalDetailById = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PARTICULAR_JOURNAL_DETAILS
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};

export const getPEForJournalHomePage = (headers, params) => {
    const finalUrl = baseURL + ApiEndPoint.GET_PE_ARTICLE_FOR_JOURNAL_HOMEPAGE
    return Api.getData(finalUrl, headers, params).catch((error) => {return error});
};
