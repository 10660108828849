import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import jwt_decode from "jwt-decode";
import alertSearch from "../../assets/img/icons/alertSearch.svg";
import active_info from "../../svg/active-info.svg";
import doneSvg from "../../assets/img/icons/done02.svg";
import { toast } from "react-toastify";
import { updateAmendment, addAmendmentApi } from "../../store/apiCalls/dashboard";
import { FetchProfanityList } from "../../containers/autherFlow/submitArticle/queries";
import AmendmentTrinkaScript from "../../assets/js/Trinka-new-test";
import { checkGrammar } from "../../utils";
import GrammarSpellModal from "./GrammarSpellModal";

const RequestAmmended = ({
  amendmentPopup,
  setamendmentPopup,
  amendmentSharingPopup,
  setamendmentSharingPopup,
  setAmendmentUpdateTrigger,
  amendmentArticleId,
  articleName,
  authorName,
}) => {
  const dispatch = useDispatch();
  const amendmentRef = useRef();
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [disabled, setDisabled] = useState(false);
  const [addAmendment, setAddAmendment] = useState({ amendmentContent: "" });
  const [profanityWords, setProfanityWords] = useState([]);
  const { id } = jwt_decode(localStorage.usertoken);
  const [profanityWordsFound, setProfanityWordsFound] = useState([]);
  const [spellCheckModal, setSpellCheckModal] = useState(false);
  const [profanityAlert, setprofanityAlert] = useState({
    isOPen: false,
    type: 0,
  });

  const addAmendmentArticle = async () => {
    let error = false;
    dispatch({ type: "SET_LOADER", payload: true });
    const response = await checkGrammar(amendmentRef.current?.value);
    dispatch({ type: "SET_LOADER", payload: false });
    const result = response?.data?.url?.sentences;
    // result?.map((sentence) => {
    //   sentence.result?.forEach((item) => {
    //     if (
    //       (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
    //       (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
    //     ) {
    //       error = true;
    //       setSpellCheckModal(true);
    //     } else {
    //       setSpellCheckModal(false);
    //     }
    //   });
    // });
    if (error === true) {
      setSpellCheckModal(true);
      return;
    } else {
      setSpellCheckModal(false);
      if (validateForm()) {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
          articleId: amendmentArticleId,
          authorName: authorName,
          articleName: articleName,
          amendmentType: "New Request",
          userId: id,
          amendmentContent: amendmentRef.current?.value,

          // amendmentData: [
          //   {
          //     amendmentContent: amendmentRef.current?.value,
          //     amendmentDate: new Date(),
          //   },
          // ],
        };
        // const response = await updateAmendment(headers, params);
        const response = await addAmendmentApi(headers, params);
        // console.log("params",response);
        // if (false) {
        if (response?.status === 200) {
          setamendmentPopup(false);
          setamendmentSharingPopup(true);
          setAmendmentUpdateTrigger(true);
          setAddAmendment({ amendmentContent: "" });
          dispatch({ type: "SET_LOADER", payload: false });
        } else {
          dispatch({ type: "SET_LOADER", payload: false });
          return toast.error("Please try again");
        }
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const commonDetl = addAmendment.amendmentContent;
    let isProfinityWord = false;
    const profanityWordList = [];
    for (var i = 0; i < profanityWords.length; i++) {
      if (isExactMatch(commonDetl?.toLowerCase(), profanityWords[i])) {
        isProfinityWord = true;
        profanityWordList.push(profanityWords[i]);
      }
    }
    setProfanityWordsFound(profanityWordList);
    if (isProfinityWord === true) {
      valid = false;
      setprofanityAlert({ isOPen: true, type: 0 });
    }
    return valid;
  };

  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };
  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  const sucessHandlerProfanity = (profanity) => {
    if (profanity.status === 200) {
      setProfanityWords(profanity.data.data);
    }
  };

  async function getProfanity() {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      headers: headers,
    };

    getProfanityList(queryParams);
  }
  const { mutate: getProfanityList } = FetchProfanityList(
    sucessHandlerProfanity
  );

  useEffect(() => {
    const script = document.createElement("script");
      script.src = AmendmentTrinkaScript;
      script.id = 'amendmentTrinkaId'
      script.async = true;
      if (!document.getElementById('amendmentTrinkaId')) {
        document.body.appendChild(script);
      }
    getProfanity();
  }, []);

  useEffect(() => {
    setDisabled(!addAmendment?.amendmentContent);
  }, [addAmendment]);

  return (
    <>
      <Modal
        dialogclassName="modal-dialog-centered modal-lg"
        show={amendmentPopup}
        size="lg"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">REQUEST AMENDMENT IN THE ARTICLE</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setamendmentPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="amendmentPopup">
            <div className="row">
              <div className="col-md-5">
                <div className="fieldWrap">
                  <label class="labelForm">Author Name</label>
                  <div className="inputTextBox">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter"
                      readOnly
                      value={authorName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="fieldWrap">
                  <label class="labelForm">Article Name</label>
                  <div className="inputTextBox">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter"
                      readOnly
                      value={articleName}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="fieldWrap">
                  <label class="labelForm amendedTextArea">
                    <span>
                      Please provide the amended content in the box below
                    </span>

                    <div className="guidelinesWrap pr-0">
                      <div className="toolWrap top03">
                        Guidelines
                        <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap">
                                      <h2>Article Amendment Guidelines</h2>
                                      <p>
                                        Please note the amendment section has a
                                        word limit of 500 words. However, please
                                        keep the note precise. An amendment
                                        explains any corrections, additions or
                                        modifications that have been made to the
                                        original article. It provides
                                        transparency and accountability to the
                                        research.
                                      </p>
                                      <p>
                                        In general, the amendment note should
                                        include the following information:
                                      </p>
                                      <ul>
                                        <li>
                                          A brief description of the change that
                                          has been made.
                                        </li>
                                        <li>
                                          The reason for the change, if
                                          applicable.
                                        </li>
                                        <li>The date the change was made.</li>
                                        <li>
                                          The location of the change within the
                                          article (e.g., page number, section
                                          heading)
                                        </li>
                                      </ul>
                                      <p>
                                        If the changes is significant or
                                        complex, it will be considered as an
                                        updated version of the article. You will
                                        then need to resubmit the entire article
                                        and pay additional fee. For more
                                        information, please write to{" "}
                                        <a href="">
                                          contactus@vikramshilaedu.in
                                        </a>{" "}
                                      </p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div>
                      </div>
                    </div>
                  </label>
                  <div className="inputTextBox">
                    <textarea
                      className="form-control"
                      type="text"
                      ref={amendmentRef}
                      placeholder="Please enter the content..."
                      maxLength={500}
                      onChange={(e) =>
                        setAddAmendment({
                          ...addAmendment,
                          amendmentContent: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary"
            type="button"
            onClick={addAmendmentArticle}
            disabled={disabled}
          >
            SUBMIT
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName="modal-sm modal-dialog  "
        size="md"
        show={amendmentSharingPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">Thank you for sharing <br/>the amendment</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setamendmentSharingPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="amendmentSharing">
            <p>
              Your request is successfully accepted. Please check your email for
              more details.
            </p>

            <p>
              Please note that it will take an estimated time of{" "}
              <u>seven working days</u> to accept and reflect the changes.
            </p>

            <p>
              You will receive a notification via email once the changes have
              been made.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary sm ml-3"
            onClick={(e) => setamendmentSharingPopup(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogclassName=""
        show={profanityAlert.isOPen}
        id="congratsMobile"
        tabIndex={-1}
        className={
          profanityAlert.type === 0
            ? "authorModal md-x"
            : "authorModal rightSide profanity-content-right"
        }
        centered
      >
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            <img src={alertSearch} alt="#" />
          </div>
          <h5 className="modal-title">PROFANITY check Results</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
          <div className="grantDetails alertPopup">
            <div className="checkResultText">
              <strong>
                Thank you for sharing your amendment request for this article.{" "}
              </strong>
              <p>
                Before your request is processed, please make sure that the
                following words are removed :
              </p>
            </div>

            <div className="offensiveBox">
              <ol>
                {profanityWordsFound.map((wrd, index) => (
                  <li>{wrd}</li>
                ))}
              </ol>
            </div>
            <div className="mt-4 mb-3">
              {profanityAlert.type === 0 && (
                <button
                  className="button button-primary"
                  type="button"
                  onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
                >
                  Make Amendments
                </button>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-0">
          <div className="helpPopupText pb-3">
            Need help? Write to us at
            <a href="mailto:contactus@vikramshilaedu.in">
              contactus@vikramshilaedu.in
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      <GrammarSpellModal
        spellCheckModal={spellCheckModal}
        setSpellCheckModal={setSpellCheckModal}
        mainContent={"Thank you for sharing your amendment request for submission."}
        upperContent={"We have noticed that there are still some grammar and language issues that need your attention."}
        middleContent={"You will find them highlighted in the amendment request for correction."}
        lowerContent={"Before your amendment request is processed, please make sure that the highlighted issues are fixed."}
      />
    </>
  );
};

export default RequestAmmended;
