// https://orcid.org/oauth/authorize?client_id=APP-RAKRR9CDZ5FK8R7Z
// &response_type=code&scope=openid&redirect_uri=https://unikove.com/projects/orcid/orcid.php

import React, { useRef, createRef, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import html2canvas from "html2canvas";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Grammarly } from "@grammarly/editor-sdk-react"; //GrammarlyEditorPlugin
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { isMobile } from 'react-device-detect';
import Popover from "react-bootstrap/Popover";
// import TrinkaScript from "../../assets/js/Trinka-new-test";
import {
  UploadArticleContent,
  startSimilarityCheck,
  downloadSimiliratyReport,
} from "../../store/apiCalls/profileDetails";
import { jsPDF } from "jspdf";
import { SERVER_URL } from "../../config";

import jwt_decode from "jwt-decode";
import SunRichText from "./SunEditor";
/*eslint-disable*/
import MySubmission from "./preSubmitArticleDetails/mySubmission";
import StarRating from "./starRating";
import ContentDetails from "../viewArticle/contentDetails";
import "../../containers/autherFlow/personalDetails/PersonalDetails.scss";
import jwtDecode from "jwt-decode";
import { useDispatch,useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import doneSvg from "../../assets/img/icons/done02.svg";
import alertSvg from "../../assets/img/icons/alert_info.svg";
import alertSearch from "../../assets/img/icons/alertSearch.svg";
import rightChk from "../../assets/img/icons/tickChk.svg";
import feedWant from "../../assets/img/icons/wantFeed.svg";
import coins from "../../assets/img/icons/coins.svg";
import kalashIcon from "../../assets/img/icons/kalash.png";
import email48 from "../../assets/img/icons/icons-gmail.png";
import whatsapp48 from "../../assets/img/icons/whatsapp-48.png";
import twitter48 from "../../assets/img/icons/twitter-48.png";
import Input from "./Input";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import downloadImg from "../../assets/img/icons/downloadBlue.svg";
import tickChkImg from "../../assets/img/icons/tickChk.svg";
import CheckPri from "../../assets/img/icons/checkpri.svg";

import {
  FetchArticleById,
  EditCurrentArticle,
  SaveToDraft,
  FetchSimiilarityReport,
  findReviewer,
  FetchProfanityList,
  SaveAuthorsFeedback
} from "../../containers/autherFlow/submitArticle/queries";
import { getAllMasterData } from "../../store/apiCalls/fetchmaster";
import { UploadMediaWithName } from "../../store/apiCalls/profileDetails";
import { getAPCValues } from "../../store/apiCalls/articles";

/*eslint-disable*/
import { checkUrl, getUnique, downLoadFile, toTitleCase,checkEmptyArticle, checkGrammar } from "../../utils";
import active_info from "../../svg/active-info.svg";
import { EmailShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import GrammarSpellModal from "../Modal/GrammarSpellModal";
import { exitCode } from "process";

const SubmitArticle = ({ }) => { 
  const shareUrl = SERVER_URL; 
  // var trinka="";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.LoginReducer);
  // console.log(user);
  const code = useSelector((state) => state?.LoginReducer?.user?.promoCode)
  const gramrlyClientID = process.env.REACT_APP_GRAMMERLY_ID;
  const [trinka, settrinka] = useState("");
  const [articleID, setarticleID] = useState("");
  const [articleType, setarticleType] = useState("");
  const [spellCheckModal, setSpellCheckModal] = useState(false);
  const [isForRevision, setisForRevision] = useState(false);
  const [showprePage, setshowprePage] = useState(true);
  const [profanitySuccess, setprofanitySuccess] = useState(false);
  const [isEdited, setisEdited] = useState(true);
  const [profanityWords, setProfanityWords] = useState([]);
  const [subjectList, setsubjectList] = useState([]);
  const [invalidFilePopup, setinvalidFilePopup] = useState(false);
  const [APCData, setAPCData] = useState([]);
  const [citationPopup, setcitationPopup] = useState(false);
  const [isOpenTableContent, setisOpenTableContent] = useState(false);
  const [iSaveDraft, setiSaveDraft] = useState(0);
  const [masterDataLoaded, setmasterDataLoaded] = useState(false);
  const [appointArticleStatus, setAppointArticleStatus] = useState("");
  const [simillarityResult, setsimillarityResult] = useState({
    percent: 0,
    status: "",
    indexId: "",
  });
  

  const [savedDetailsPupup, setsavedDetailsPupup] = useState(false);
  const [mobileAlert, setMobileAlert] = useState(false);
  const [prePaymentStatus, setprePaymentStatus] = useState(false);
  const [postValueStatus, setpostValueStatus] = useState(false);
  const [subjectSpecializationList, setsubjectSpecializationList] = useState(
    []
  );
  const [blinkError, setblinkError] = useState("");
  const [specializationList, setspecializationList] = useState([]);
  const [desciplineMaster, setdesciplineMaster] = useState([]);
  const [articleTypeList, setarticleTypeList] = useState([]);
  const [supplimentImges, setsupplimentImges] = useState([]);
  const [profanityWordsFound, setProfanityWordsFound] = useState([]);
  const tokenVal = jwtDecode(localStorage.usertoken);
  const [gernalMaster, setgernalMaster] = useState([]);
  const [gernalType, setgernalType] = useState([]);
  const [preStepData, setpreStepData] = useState({
    plagirismCheck: "notDone",
    status: "draft",
    articleVersion: "",
    initialPayment: true,
    submissionDetail: {},
    statements: {},
    reviewType: {},
    authorDtl: {},
    fundingDetails: {},
    conference: {},
  });
  

  const [completedTabs, setcompletedTabs] = useState({
    mySubmission: false,
    review: false,
    author: false,
    funding: false,
    statement: false,
    conference: false,
  });
  const [showResult, setShowResult] = useState(false);
  const [isUpdateNewVersion, setisUpdateNewVersion] = useState(false);
  const [isUpdateNewVersionPopup, setisUpdateNewVersionPopup] = useState(false);
  const [authorAffiliationsCount, setauthorAffiliationsCount] = useState([0]);
  const [mainTitle, setmainTitle] = useState("");
  const [finalSubmitPupup, setfinalSubmitPupup] = useState(false);
  const [finalSubmitConfirmPupup, setfinalSubmitConfirmPupup] = useState(false);
  const [feedbackPupup, setfeedbackPupup] = useState({openPopup:false,successPopup:false,starVal:0,feedback:'',rateError:'',feedbackError:''});
  const [showReferAndEarn, setShowReferAndEarn] = useState(false);
  const [articleWordLimit, setarticleWordLimit] = useState({wordLimitStatus:'',wordLimitType:'',alreadyExistReqPopup:false,reqSuccessPopup:false,openPopup:false,isIncrease:false,isDecrease:false,detail:'',detailError:''});
  const [mendatoryAlert, setmendatoryAlert] = useState("");
  const [articleContent, setarticleContent] = useState({
    id: "",
    runningTitle: "",
    abstract: "", 
    title: "",
    abbreviations: [],
    abbreviationsFull: [],
    keywords: [],
    references: "",
    acknowledgements: "",
    status: "",
  });
  
  const [articleContentError, setarticleContentError] = useState({
    titleError: "",
    runningTitleError: "",
    abstractError: "",
    introductionError: "",
    keywordsError: "",
  });
  const [citationDetails, setcitationDetails] = useState({publishPopup:false,totalLength:[0],url:'',isMulti:false,year:'',artTitle:'',urlError:'',yearError:'',fname:[{val:''}],lname:[{val:''}],fnameError:[],lnameError:[],artTitleError:[]})
  const [cancelArticle, setCancelArticle] = useState(false);
  const [editorLength, seteditorLength] = useState([0, 1, 2, 3, 4, 5]);
  const [uploadeImages, setuploadeImages] = useState([]);
  const [editorTitleValue, seteditorTitleValue] = useState([
    { val: "Introduction" },
    { val: "Background" },
    { val: "Methodology" },
    { val: "Results" },
    { val: "Discussion" },
    { val: "Conclusion" },
  ]);
  const [editorTitleEdit, seteditorTitleEdit] = useState([
    { val: false },
    { val: false },
    { val: false },
    { val: false },
    { val: false },
    { val: false },
  ]);
  const [editorTitleValueError, seteditorTitleValueError] = useState([
    { val: "" },
    { val: "" },
    { val: "" },
    { val: "" },
    { val: "" },
    { val: "" },
  ]);
  const [editorContentValue, seteditorContentValue] = useState([
    { val: "" },
    { val: "" },
    { val: "" },
    { val: "" },
    { val: "" },
    { val: "" },
  ]);
  const [refranceAck, setrefranceAck] = useState({
    refrances: "",
    acknowledgements: "",
  });
  const [savedDraftArticle, setsavedDraftArticle] = useState(false);
  const [saveArticleConfirmation, setsaveArticleConfirmation] = useState(false);
  const [saveArticleConfirmationNew, setsaveArticleConfirmationNew] = useState(false);
  const [finalSubmitds, setfinalSubmitds] = useState("false");
  const [showDownloadbtn, setShowDownloadbtn] = useState(false);
  const [profanityAlert, setprofanityAlert] = useState({
    isOPen: false,
    type: 0,
  });
  const [plagiarismAlert, setPlagiarismAlert] = useState(false);
  const headers = { Authorization: `Bearer ${localStorage.usertoken || ""}` };
  const [unicheckData, setUnicheckData] = useState({
    similaritycheckId: null,
    url: null,
  });
  const router = useLocation();
  const keyRef = useRef("");
  const submissionRef = useRef(null);
  const iSaveDraftRef = useRef(iSaveDraft);
  iSaveDraftRef.current = iSaveDraft;
  const editorContentValueRef = useRef(editorContentValue);
  editorContentValueRef.current = editorContentValue;
  const abbreviationsRef = useRef("");
  const abbreviationsFullRef = useRef("");
  const refranceRef = useRef(null);
  const acknowledgementRef = useRef(null);
  const articleContentRefs= useRef(editorLength.map(() => createRef()));

  const title =
  `I have registered with Aakashganga, a new open access journals platform, built with love in India, for the world.

  As a researcher, I appreciate their vision of removing traditional barriers to publishing research, and democratizing knowledge through their automated digital Open Access Journals Platform to accelerate scientific progress. 
  
  I invite you to join Aakashganga. Use the special invitation code: ${code} to register on the platform and publish your research. Their APCs are one of the lowest in the world, and their smart automated solutions fast track research publishing.`

  const handleCodeCopy = (e) => {
    
    copy(code);
    e.target.textContent = "Copied";
    e.target.style.setProperty("color", "#404040");
  };
  
  const sucessHandler = (articleDetails) => {
    if (articleDetails.status === 200) { 
      const editDetl = articleDetails.data.data;
      const w=articleWordLimit;
      if(editDetl?.updateNewVersion===true)
      setisUpdateNewVersion(true)
      if(editDetl?.wordLimit===true)
      {
        if(editDetl.wordLimitType==='Less than 8k')
        {
          w.isIncrease=false
          w.isDecrease=true
        }
        else if(editDetl.wordLimitType==='More than 10k')
        {
          w.isIncrease=true
          w.isDecrease=false
        }
        w.wordLimitStatus=editDetl.wordLimitStatus
        w.wordLimitStatus=editDetl.wordLimitStatus
        w.wordLimitType=editDetl.wordLimitType
      }
      else{
        w.wordLimitStatus=editDetl?.wordLimitStatus
        w.wordLimitType=editDetl?.wordLimitType
      }

      setarticleWordLimit({...w})
      const cmpTab = completedTabs;
      const preDAta = {
        submissionDetail: {},
        statements: {},
        reviewType: {},
        authors: {},
        fundingDetails: {},
        conference: {},
        validCouponCode: {},
        initialPayment: "",
        paymentBySociety: {}, 
      }; 


      if (
        editDetl.submissionDetail.journalName &&
        editDetl.submissionDetail.journalName !== ""
      ) {
        
        cmpTab.mySubmission = true;
        preDAta.submissionDetail = editDetl.submissionDetail;
        preDAta.initialPayment = editDetl.initialPayment;
        preDAta.validCouponCode = editDetl.validCouponCode;
        preDAta.paymentBySociety = editDetl.paymentBySociety;
      }
      if (editDetl?.conference?.conferenceName !== ""
      )
      {
        cmpTab.conference = true;
        preDAta.conference = editDetl.conference;
      }
      if (
        editDetl.reviewType?.typeOfReview &&
        editDetl.reviewType?.typeOfReview !== ""
      ) {
        cmpTab.review = editDetl.initialPayment === true ? true : false;
        preDAta.reviewType = editDetl.reviewType;
        preDAta.reviewType.paymentDone = editDetl.initialPayment;
      }
      if (editDetl.authorDetails?._id && editDetl.authorDetails?._id !== "") {
        cmpTab.author = true;
        preDAta.authorDtl = editDetl.authorDetails;
      }

      preDAta.plagirismCheck = editDetl.plagirismCheck;
      if (
        editDetl.similarityCheckPercentage &&
        editDetl.similarityCheckPercentage > 15 &&
        editDetl.plagirismCheck === "Done" &&
        editDetl.status === "draft"
      ) {
        preDAta.plagirismCheck = "notDone";
        setblinkError(
          editDetl.plagirismCheck === "Done"
            ? `ATTENTION: Similarity Index Check - ${editDetl.similarityCheckPercentage}%`
            : ""
        );
        setsimillarityResult({
          percent: editDetl.similarityCheckPercentage,
          status: editDetl.plagirismCheck,
          indexId: editDetl.similarityCheckId,
        });
      } else if (
        editDetl.plagirismCheck === "Done" &&
        editDetl.similarityCheckPercentage >= 0 &&
        editDetl.similarityCheckPercentage <= 15
      ) {
        setsimillarityResult({
          percent: editDetl.similarityCheckPercentage,
          status: editDetl.plagirismCheck,
          indexId: editDetl.similarityCheckId,
        });
      } else if (
        editDetl.plagirismCheck === "Done" &&
        !editDetl.similarityCheckPercentage
      ) {
        preDAta.plagirismCheck = "notDone";
      }
      if (editDetl.plagirismCheck === "reject")
        preDAta.plagirismCheck = "notDone";

      if (editDetl.fundingDetails?._id && editDetl.fundingDetails?._id !== "") {
        cmpTab.funding = true;
        preDAta.fundingDetails = editDetl.fundingDetails;
      }
      
      if (
        editDetl.statements?.commercial &&
        editDetl.statements?.commercial
          ?.commercialOrfinancialConflictOfInterest !== ""
      ) {
        cmpTab.statement = true;
        preDAta.statements = editDetl.statements;
        
        setshowprePage(false);
      }
      if (editDetl.status) {
        preDAta.status = editDetl.status;
      }
      if (editDetl.articleStatus && editDetl.articleStatus === "sentForRevision") {
        preDAta.plagirismCheck = "notDone"; preDAta.status = 'draft';
        const version = editDetl.articleVersion.split(' ');
        const newVersion = parseInt(version[1]) + 1;
        preDAta.articleVersion = `Version ${newVersion}`;
        setisForRevision(true)
      }

      setpreStepData(preDAta);
      setcompletedTabs({ ...cmpTab });
      const data = articleContent;
      data.id = editDetl._id;
      data.title = editDetl.title;
      setmainTitle(editDetl.title);
      data.runningTitle = editDetl.runningTitle;
      data.abstract = editDetl.abstract;
      const refack = refranceAck;
      if (editDetl.articleData.references)
        refack.refrances = editDetl.articleData.references;
      if (editDetl.articleData.acknowledgement)
        refack.acknowledgements = editDetl.articleData.acknowledgement;
      setrefranceAck({ ...refack });
      const abbr = [];
      const abbrfull = [];
      if (editDetl.abbreviations) {
        editDetl.abbreviations.forEach((abbreviation, k) => {
          abbr.push(abbreviation.apprv);
          abbrfull.push(abbreviation.fullForm);
        });
      }
      data.abbreviations = abbr;
      data.abbreviationsFull = abbrfull;
      if (editDetl.status) {
        data.status = editDetl.status;
      }
      if (editDetl.keywords)
        data.keywords = editDetl.keywords;
      if (editDetl.supplymentaryMaterial)
        setsupplimentImges([...editDetl.supplymentaryMaterial]);
      const editorLength = [];
      const titleData = [];
      const DescData = [];
      const errorTitle = [];
      const isEditerTitleEdit = [];
      editDetl.articleData.articleSection.forEach((detlart, k) => {
        editorLength.push(k);
        titleData.push({ val: detlart.title });
        DescData.push({ val: detlart.content });
        errorTitle.push({ val: "" });
        isEditerTitleEdit.push({ val: false });
      });
      if (editorLength.length > 0) {
        seteditorTitleEdit([...isEditerTitleEdit]);
        seteditorLength([...editorLength]);
        seteditorTitleValue([...titleData]);
        seteditorContentValue([...DescData]);
        seteditorTitleValueError([...errorTitle]);
      }

      setarticleContent({ ...data });
      if (editDetl?.images) setuploadeImages(editDetl?.images);
      // console.log(preDAta,"preDAta")
      setisEdited(false);
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };
  const sucessHandlerUpdate = (articleDetails) => {
    if (articleDetails.status === 200) {
      if (postValueStatus === true) setsavedDraftArticle(true);
      else setpostValueStatus(true);
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const sucessHandlerSaveDraft = (articleDetails) => {
    if (articleDetails.status === 200) {
      const data = articleContent;
      if(finalSubmitds=="adminctrl"){
        handleRedirect("", `/dashboard`);
      }

      const data1 = articleContent;
      data1.id = articleDetails.data.articleId;
      setarticleContent({ ...data1 });


      // console.log(articleDetails,'===========');
      if (articleDetails.data.articleId && articleDetails?.data?.statements?.AakashGangaJournal?.versionOfManuscript) {
        
        data.id = articleDetails.data.articleId;
          // console.log(articleDetails.data.articleId,"articleIdarticleIdarticleIdarticleIdarticleId")
          handleRedirect("", `/dashboard/edit-article/${articleDetails.data.articleId}`);
        
        setarticleContent({ ...data });
      }
      if (iSaveDraftRef.current === 1) {
        setsavedDetailsPupup(true);
      } else if (iSaveDraftRef.current === 2) {
        setprofanitySuccess(true)
      } else if (iSaveDraftRef.current === 3) {
          if(isUpdateNewVersion===false)
          setfinalSubmitConfirmPupup(true);
          else
          setisUpdateNewVersionPopup(true)
        // assigneReviwer();
      } else if (iSaveDraftRef.current === 4) {
        const articleID = articleContent.id;
        handleRedirect("", `/perviewArticle/${articleID}`);
      }
      else if (iSaveDraftRef.current === 5) {
        paymentInitiation();
      }
      else if (iSaveDraftRef.current === 6) {
        const w=articleWordLimit;
        w.detail='';
        w.reqSuccessPopup=true;
        w.openPopup=false;
        setarticleWordLimit({...w})
      }
    }

    dispatch({ type: "SET_LOADER", payload: false });
  };
  const sucessHandlerSimillarityReport = (articleDetails) => {
    if (articleDetails.status === 200) {
      downLoadFile(articleDetails.data.url, "report.pdf");
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const sucessHandlerFindReviewer = (articleDetails) => {
    if (articleDetails.status === 200) {
      setAppointArticleStatus(articleDetails?.data?.data?.status);
      if(isUpdateNewVersion===false)
      setfinalSubmitConfirmPupup(true);
      else
      setisUpdateNewVersionPopup(true)
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };
  

  
 const { mutate: getArticleById } = FetchArticleById(sucessHandler);
  const { mutate: updateArticle } = EditCurrentArticle(sucessHandlerUpdate);
  const { mutate: saveArticleToDraft } = SaveToDraft(sucessHandlerSaveDraft);
  const { mutate: downloadSimiliratyReport } = FetchSimiilarityReport(
    sucessHandlerSimillarityReport
  );
  const { mutate: assigneArticleToReview } = findReviewer(
    sucessHandlerFindReviewer
  );
  useEffect(() => {

    // let output = document.querySelector(`[id="output"]`);
    // var trinka = new Trinka({
    //   alertCountChangeEvent: (editorId, count) => { console.log('[AG-DEBUG] alert count updated', editorId, count) }
    // }); 
    // settrinka(trinka);
    // console.log('[AG-DEBUG] trinka instance ===> ', trinka);

    // const script = document.createElement("script");
    // script.src = TrinkaScript;
    // script.id = 'trinkaId'
    // script.async = true;
    // if (!document.getElementById('trinkaId')) {
    //   document.body.appendChild(script);
    // }
   
    getMasterList();
   
    if (router.pathname.includes("/dashboard/edit-article")) {
      const articleID = router.pathname.split("/")[3];
      setarticleID(articleID);
      getArticleDetail(articleID);
    } else {
      setisEdited(false);
    }
    getProfanity()
    handleAPCData()

    setMobileAlert(isMobile);
    
  }, []);

  const handleAPCData = async () => { 
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` }; 
    const params = {
      typeOfApc: user.nationality??'withInIndia',
    };
    const response = await getAPCValues(headers, params);
    if (response?.status === 200) {
      setAPCData(response?.data?.data); 
    } else {
      return toast.error("Please try again!!");
    }
  };

  const sucessHandlerProfanity = (profanity) => { 
    if (profanity.status === 200) { 
      setProfanityWords(profanity.data.data);
      
    }
    
  };
  async function getProfanity() {
    // console.log("getProfanity",getProfanity);
    // const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    // const queryParams = {
    //   headers: headers,
    // };

    // getProfanityList(queryParams);
  }
  const { mutate: getProfanityList } = FetchProfanityList(sucessHandlerProfanity);
  async function getArticleDetail(id) {
    //
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { articleId: id },
      headers: headers,
    };

    getArticleById(queryParams);
  }

  function getDetailsInfo() {
    if (articleID === "") {
    }
    setshowprePage(true);
  }

  async function getMasterList() {
    const msterlst = await getAllMasterData();

    if (msterlst.status === 200) {
      setspecializationList(msterlst.data.specializationdata);
      const journalMaster = [];
      const journalType = [];
      msterlst.data.jouranlData.forEach((element) => {
        const data = { key: element._id, value: element.journalType };
        journalType.push(data);
        journalMaster[element._id] = element.journalName;
      });

      setgernalMaster(journalMaster);
      setgernalType(journalType);
      setdesciplineMaster(msterlst.data.disciplineData);
      const affiliation = [];
      msterlst.data.affiliateddata.forEach((element) => {
        const data = { key: element._id, value: element.affiliated };
        affiliation.push(data);
      });
      
      const subjectLists = [];
      const subjectSpecialization = [];
      msterlst.data.subjectdata.forEach((element) => {
        const data = { key: element._id, value: element.subjectName };
        subjectLists.push(data);
        subjectSpecialization[element._id] = element.specializationId;
      });

      setsubjectList(subjectLists);
      setsubjectSpecializationList(subjectSpecialization);
      const typeOfArticledata = [];
      msterlst.data.typeOfArticledata.forEach((element) => { 
        const data = { key: element._id, value: element.articleName,description:element.description,wordCount:element.wordCount };
        typeOfArticledata.push(data);
      });
      setarticleTypeList(typeOfArticledata);
    }
    setmasterDataLoaded(true);
  }
  const handleRedirect = (e, path) => {
    if (e !== "") e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const escapeRegExpMatch = function (s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  };
  const isExactMatch = (str, match) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
  };

  function checkRequiredField(type) {
    
    const regex = /(<([^>]+)>)/gi;
    setblinkError("");
    const intro = editorContentValue[0].val.replace(regex, "");
    const data = articleContentError;
    let isError = false;
    let requiredFiled = "";
    if (!(articleContent.runningTitle =="" || articleContent.runningTitle == undefined) ){
      const wordcount = (articleContent.runningTitle.trim()).split(' ');
      if(wordcount.length > 10){
        isError = true;
        data.runningTitleError = "A running title can be a maximum of 10 words in length.";
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }else {
      data.runningTitleError = "";
    }
    
    
    if (type !== "draft") {
      if (articleContent.keywords.length < 5) {
        isError = true;
        data.keywordsError = "Minimum 5 keywords are required";
        requiredFiled += "Keywords";
      }
      if (mainTitle === "" || mainTitle === undefined) {
        
        isError = true;
        data.titleError = "Please enter title";
        if (requiredFiled !== "") requiredFiled += ", ";
        requiredFiled += "Title";
      } else {
        data.titleError = "";
      }
      if (mainTitle !=""){
        const wordcount = (mainTitle.trim()).split(' ');
        
        if(wordcount.length > 20){
          isError = true;
          data.titleError = "A title can be a maximum of 20 words in length.";
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }else {
        data.titleError = "";
      }
      if (!articleContent.abstract || articleContent.abstract === "") {
        isError = true;
        data.abstractError = "Please enter abstract";
        if (requiredFiled !== "") requiredFiled += ", ";
        requiredFiled += "Abstract";
      } else if (
        articleContent.abstract &&
        articleContent.abstract.split(" ").length > 500
      ) {
        isError = true;
        data.abstractError = "500 words are allowed";
      } else {
        data.abstractError = "";
      }
      if (intro === "") {
        isError = true;
        data.introductionError = "Please enter introduction";
        if (requiredFiled !== "") requiredFiled += ", ";
        requiredFiled += "Introduction";
      } else {
        data.introductionError = "";
      }
    }
    const editorTitleError = editorTitleValueError;
    let titleerro = false;
    let allEditorContent = "";
    for (let e = 0; e < editorLength.length; e++) {
      if (editorTitleValue[e].val === "") {
        titleerro = true;
        editorTitleError[e].val = "This filed is required";
      } else {
        if(editorTitleValue[e].val!=='Introduction')
        allEditorContent += editorContentValue[e].val;
      }
    }
   
    
    const htmlFreeData = allEditorContent.replace(regex, "");
    const wrdlimit=articleWordLimit; 
    if (htmlFreeData.split(" ").length > 10000 && type !== "draft" && articleWordLimit.wordLimitStatus!=='approved') {
      isError = true;
      if (requiredFiled === "") {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setblinkError("ATTENTION: Word limit exceeded");
        wrdlimit.openPopup=true
        wrdlimit.isIncrease=true
        wrdlimit.isDecrease=false
        setarticleWordLimit({...wrdlimit})
      }
    }
    else if (htmlFreeData.split(" ").length < 200 && type !== "draft" && articleWordLimit.wordLimitStatus!=='approved') {
      isError = true;
      if (requiredFiled === "") {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setblinkError("ATTENTION: Word Limit Requirement Not Met");
        wrdlimit.openPopup=true
        wrdlimit.isIncrease=false
        wrdlimit.isDecrease=true
        setarticleWordLimit({...wrdlimit})
      }
    }
    
    if (titleerro === true) {
      isError = true;
      seteditorTitleValueError([...editorTitleError]);
    }
    setmendatoryAlert('');
    if (requiredFiled !== "") {
      setmendatoryAlert(`Mandatory Sections - ${requiredFiled}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setarticleContentError({ ...data });
    return isError;
  }
 
  function sendLimitReason()
  {
    const w=articleWordLimit;
    if(articleWordLimit.detail!=='' && articleWordLimit.wordLimitStatus!=='newRequest')
    {
      setiSaveDraft(6); 
      dispatch({ type: "SET_LOADER", payload: true });
      const data = getPayload("draft");
      let wtype="Less than 8k";
      if(articleWordLimit.isIncrease===true)
      wtype="More than 10k";
      data.saveDetails.wordLimit=true;
      data.saveDetails.wordLimitType=wtype;
      data.saveDetails.wordLimitReason=articleWordLimit.detail;
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const queryParams = {
        payload: data.saveDetails,
        headers,
      };
      w.wordLimitStatus='newRequest';
      w.wordLimitType=wtype;
      saveArticleToDraft(queryParams);
    }
    else if(articleWordLimit.detail==='' && articleWordLimit.wordLimitStatus!=='newRequest'){
      
      w.detailError="Please provide valid reason"
      
    }  
    else if(articleWordLimit.wordLimitStatus==='newRequest'){
      
      w.alreadyExistReqPopup=true
      w.openPopup=false
    }
    setarticleWordLimit({...w});
  }

  function collectUpdates()
  {
    const data = editorContentValueRef.current;
    for (let k = 0; k < editorLength.length; k++) {
        data[k].val = articleContentRefs[k]?.getEditorContent();
      }
    seteditorContentValue([...data]);
  }

  function getPayload(type) {
    collectUpdates();
    const { id } = jwt_decode(localStorage.usertoken);

    const abbreviations = [];
    for (var i = 0; i < articleContent.abbreviations.length; i++) {
      const abbre = {
        apprv: articleContent.abbreviations[i],
        fullForm: articleContent.abbreviationsFull[i],
      };
      abbreviations.push(abbre);
    }
    const articleSection = [];
    let plagrism = "";

    for (let k = 0; k < editorLength.length; k++) {
      const content = {
        title: editorTitleValue[k].val,
        content: editorContentValue[k].val,
        order: k + 1,
      };
      articleSection.push(content);
      plagrism += editorContentValue[k].val + " ";
    }
    plagrism += refranceAck.acknowledgements + " ";
    plagrism += refranceAck.refrances + " ";
    const data = {
      userId: id,
      title: mainTitle,
      runningTitle: articleContent.runningTitle,
      abstract: articleContent.abstract,
      articleSection: articleSection,
      keywords: articleContent.keywords,
      formulae: [],
      citations: [],
      abbreviations: abbreviations,
      supplymentaryMaterial: supplimentImges,
      status: type,
      stage: finalSubmitds,
      images: uploadeImages,
      acknowledgement: acknowledgementRef.current?.getEditorContent(),
      references: refranceRef.current?.getEditorContent(),
    };
    if (articleContent.id !== "") data.articleId = articleContent.id;

    const detailsData = { saveDetails: data, plagrismeCheck: plagrism };
    return detailsData;
  }
  async function submitAsDraft() {
    setprofanityAlert({ isOPen: false, type: 0 });
    const isRequired = checkRequiredField("draft");
    if (isRequired === false) {
      setiSaveDraft(1);
      dispatch({ type: "SET_LOADER", payload: true });

      const data = getPayload("draft");
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const queryParams = {
        payload: data.saveDetails,
        headers,
      };
      saveArticleToDraft(queryParams);
    }
  }
  async function previewArticle() {
    setprofanityAlert({ isOPen: false, type: 0 });
    const isRequired = checkRequiredField("draft");
    if (isRequired === false) {
      setiSaveDraft(4);
      dispatch({ type: "SET_LOADER", payload: true });

      const data = getPayload("draft");
      const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const queryParams = {
        payload: data.saveDetails,
        headers,
      };
      if (preStepData?.plagirismCheck === "Done") {
        dispatch({ type: "SET_LOADER", payload: false });
        const articleID = articleContent.id;
        handleRedirect("", `/perviewArticle/${articleID}`);
      } else {
        saveArticleToDraft(queryParams);
      }
    }
  }

 

  const submitArticleWithGrammar = async () => {
    // collectUpdates(); 
    // let allEditorContent = ""; 
    // dispatch({ type: "SET_LOADER", payload: true });
    let error = false;
    // const regex = /(<([^>]+)>)/gi;
    // for (let e = 0; e < editorLength.length; e++) {
    //   if(editorTitleValue[e].val!=='') {
    //     const data= editorContentValue[e].val;
    //     const htmlFreeData = data.replace(regex, "");
    //       //  const response = await checkGrammar(htmlFreeData);
    //        const result = response?.data?.url?.sentences;
    //        result?.map((sentence) => {
    //         sentence.result?.forEach((item) => {
    //           if (
    //             (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
    //             (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
    //             (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
    //             (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
    //             (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
    //           ) {
    //             error = true;

                
    //           } else {
                
    //           }
    //         })
    //       })
    //   }
    // }
    // const refData= refranceRef.current?.getEditorContent();
    // if(refData=='') {
    // const htmlFreeDataRef = refData.replace(regex, "");
    // const responseRef = await checkGrammar(htmlFreeDataRef);
    // const resultRef = responseRef?.data?.url?.sentences;
    //  resultRef?.map((sentence) => {
    //       sentence.result?.forEach((item) => {
    //         if (
    //           (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
    //         ) {
    //           error = true;
              
    //         } 
    //       })
    //     })
    //   }
    //   const ackData= acknowledgementRef.current?.getEditorContent();
    //   if(ackData=='') {
   
    // const htmlFreeDataackData = ackData.replace(regex, "");
    // const responseAck = await checkGrammar(htmlFreeDataackData);
    // const resultAck = responseAck?.data?.url?.sentences;
    // resultAck?.map((sentence) => {
    //       sentence.result?.forEach((item) => {
    //         if (
    //           (item?.cta_present?.[0] === true && (item?.error_category?.[0] === "Word/Phrase choice" && item?.error_category?.[0] === "Spellings & Typos" && item?.error_category?.[0] === "Verbs" && item?.error_category?.[0] === "Subject-verb agreement")) ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Word/Phrase choice") ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Spellings & Typos") ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Verbs") ||
    //           (item?.cta_present?.[0] === true && item?.error_category?.[0] === "Subject-verb agreement")
    //         ) {
    //           error = true;
              
    //         } 
    //       })
    //     })
    //   }
    if (error === true) {
      dispatch({ type: "SET_LOADER", payload: false });
      setSpellCheckModal(true);
      return;
    } else {
      // dispatch({ type: "SET_LOADER", payload: false });
      // setblinkError("");
      submit();
    }
  }

  function submit() {
    const isRequired = checkRequiredField("similarityCheck");
    if (isRequired === false) setsaveArticleConfirmationNew(true);
  }

  function assigneReviwer() {
    dispatch({ type: "SET_LOADER", payload: true });

    const data = {
      articleId: articleContent.id,
    };
    const saveData = { headers: headers, payload: data };
    assigneArticleToReview(saveData);
  }

  function finalSubmitNow() {
    dispatch({ type: "SET_LOADER", payload: true });
    setiSaveDraft(3);
    setfinalSubmitPupup(false);
    const { id } = jwt_decode(localStorage.usertoken);
    const data = {
      userId: id,
      articleId: articleContent.id,
      status: "submit",
      // journaldstype: "societyArticle",
    };
    if (preStepData.articleVersion !== '')
      data.articleVersion = preStepData.articleVersion;
    const saveData = { headers: headers, payload: data };
    saveArticleToDraft(saveData);
  }
  async function confirmToSubmitArticle() {
   
    setsaveArticleConfirmationNew(false);
    setfinalSubmitds("false");
    setprofanityAlert({ isOPen: false, type: 0 });

    // dispatch({ type: "SET_LOADER", payload: true });
    // const data = getPayload("similarityCheck");
    const data = getPayload("draft");
    
    // setiSaveDraft(2);
    let isProfinityWord = false;
    // const profanityWordList = [];
    // for (var i = 0; i < profanityWords.length; i++) {
    //   if (isExactMatch(data.plagrismeCheck.toLowerCase(), profanityWords[i])) {
    //     isProfinityWord = true;
    //     profanityWordList.push(profanityWords[i]);
    //   }
    // }

   
    if (isProfinityWord) {
      // data.saveDetails.profanityWordsPresent = {
      //   isPresent: true,
      //   words: profanityWordList,
      // };
      // setProfanityWordsFound(profanityWordList);
      // setprofanityAlert({ isOPen: true, type: 0 });
      // dispatch({ type: "SET_LOADER", payload: false });
      // setblinkError("PROFANITY CHECK RESULTS");
    } else {
      
      // console.log(finalSubmitds);
      const saveData = { headers: headers, payload: data.saveDetails };
      saveArticleToDraft(saveData);
      // setblinkError('')
    }
  }
  async function confirmToSubmitArticleOld() {
    setsaveArticleConfirmation(false);
    dispatch({ type: "SET_LOADER", payload: true });
    const data = getPayload("submit");

    const fileData = data.plagrismeCheck;
    let isProfinityWord = false;
    const profanityWordList = [];
    for (var i = 0; i < profanityWords.length; i++) {
      if (isExactMatch(fileData.toLowerCase(), profanityWords[i])) {
        isProfinityWord = true;
        profanityWordList.push(profanityWords[i]);
      }
    }
    if (isProfinityWord) {
      data.saveDetails.profanityWordsPresent = {
        isPresent: true,
        words: profanityWordList,
      };
      setProfanityWordsFound(profanityWordList);
      setprofanityAlert({ isOPen: true, type: 0 });
      dispatch({ type: "SET_LOADER", payload: false });
    } else {
      const blob = new Blob([fileData], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      const txtFile = new File([blob], "down.html", { type: "text/html" });
      const formData = new FormData();
      formData.append("file", txtFile);
      const link = document.createElement("a");
      link.download = "user-info.html";
      link.href = url;
      link.click();

      try {
        const resp = await UploadArticleContent({
          data: formData,
          headers,
          userId: tokenVal?.id,
        });
        data.saveDetails.similarityCheckId = resp.data.data.similaritycheckId;
        setUnicheckData({
          ...unicheckData,
          similaritycheckId: resp.data.data.similaritycheckId,
        });
      } catch (err) {
        alert("Unicheck Error First");
        dispatch({ type: "SET_LOADER", payload: false });
      }
      setTimeout(function () {
        checkSimilartyReport(data);
      }, 10000);
    }
  }
  async function checkSimilartyReport(data) {
    try {
      const resp = await startSimilarityCheck({
        data: {
          similarityCheckId: data.saveDetails.similarityCheckId,
          userId: tokenVal?.id,
        },
        headers,
      });
      if (resp.status === 200) {
        if (resp.data.data.similarityCheckResult.attributes.similarity < 40) {
          const saveData = { headers: headers, payload: data.saveDetails };
          saveArticleToDraft(saveData);

          setsavedDraftArticle(true);
          dispatch({ type: "SET_LOADER", payload: false });
        } else {
          setPlagiarismAlert(true);
          
          dispatch({ type: "SET_LOADER", payload: false });
        }
      }
    } catch (err) {
      alert("Unicheck Error Second");
      dispatch({ type: "SET_LOADER", payload: false });
    }
  }

  function submit() {
    const isRequired = checkRequiredField();
    if (isRequired === false) setsaveArticleConfirmationNew(true);
  }

  function editInputLable(key) {
    const edit = editorTitleEdit;
    edit[key].val = true;
    seteditorTitleEdit([...edit]);
  }

  const downloadReport = async (e) => {
    e.preventDefault();
    dispatch({ type: "SET_LOADER", payload: true });
    const input = document.getElementById("textContent");
    input.style.display = "block";
    const fileData = JSON.stringify(input.innerHTML);
    let isProfinityWord = false;
    for (var i = 0; i < profanityWords.length; i++) {
      if (isExactMatch(fileData, profanityWords[i])) isProfinityWord = true;
    }
    if (isProfinityWord) {
      alert("Profanity Word found");
      dispatch({ type: "SET_LOADER", payload: false });
      return false;
    }


    html2canvas(input).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
      
      try {
        const resp = await downloadSimiliratyReport({
          data: {
            similarityCheckId: unicheckData.similaritycheckId,
            userId: tokenVal?.id,
          },
          headers,
        });
        
      } catch (err) {
        debugger;
      }
    });
    dispatch({ type: "SET_LOADER", payload: false });
  };

  function editorContent(content, type, key) { 
    const regex = /(<([^>]+)>)/gi;
    const dataError = articleContentError;
    const data = editorContentValueRef.current;
    if (key === "acknowledgements") {
      const data = refranceAck;
      data.acknowledgements = content;
      setrefranceAck({ ...data });
    } else if (key === "references") {
      const data = refranceAck;
      data.refrances = content;
      setrefranceAck({ ...data });
    } else {
      data[key].val = content;
      seteditorContentValue([...data]);
      if (editorTitleValue[key].val === "Introduction") {
        const abstrct = content.replace(regex, "");
        if (abstrct !== "") dataError.introductionError = "";
        setarticleContentError({ ...dataError });
      }
    }

    if (type === "removeImg") {
      getExtractImageFromContent(data);
    }
  }

  function getExtractImageFromContent(editorContentDetails) {
    const allImg = [];
    for (let j = 0; j < editorContentDetails.length; j++) {
      if (
        editorContentDetails[j].val !== "" &&
        editorContentDetails[j].val !== null
      ) {
        let images = editorContentDetails[j].val.match(
          /<img\s+[^>]*src="([^"]*)"[^>]*>/i
        );
        if (images) {
          for (var i = 0; i < images.length; i++) {
            if (checkUrl(images[i])) allImg.push(images[i]);
          }
        }
      }
    }
    if (refranceAck.acknowledgements !== "") {
      let images = refranceAck.acknowledgements.match(
        /<img\s+[^>]*src="([^"]*)"[^>]*>/i
      );
      if (images) {
        for (var i = 0; i < images.length; i++) {
          if (checkUrl(images[i])) allImg.push(images[i]);
        }
      }
    }

    const data = getUnique(allImg);
    setuploadeImages([...data]);
  }
  function editorUploadedImg(img, type) {
    const Images = uploadeImages;
    Images.push(img);
    const data = getUnique(Images);

    setuploadeImages([...data]);
  }
  function removeFocusFromEditorTitle(e, key) {
    const editorEditL = editorTitleEdit;
    editorEditL[key].val = false;
    seteditorTitleEdit([...editorEditL]);
  }

  function addKeyWord(type) {
    const data = articleContent;
    if (type === "keyword" && keyRef.current.value !== "") {
      data.keywords.push(keyRef.current.value);
      keyRef.current.value = "";
      if (data.keywords.length <= 5) {
        const err = articleContentError;
        err.keywordsError = "";
        setarticleContentError({ ...err });
      }
    } else if (
      type === "abbreviations" &&
      abbreviationsRef.current.value !== "" &&
      abbreviationsFullRef.current.value !== ""
    ) {
      data.abbreviations.push(abbreviationsRef.current.value);
      data.abbreviationsFull.push(abbreviationsFullRef.current.value);
      abbreviationsRef.current.value = "";
      abbreviationsFullRef.current.value = "";
    }
    setarticleContent({ ...data });
  }
  function removeKeyWord(type, key) {
    const data = articleContent;
    if (type === "keyword") {
      data.keywords.splice(key, 1);
    } else if (type === "abbreviations") {
      data.abbreviations.splice(key, 1);
      data.abbreviationsFull.splice(key, 1);
    }

    setarticleContent({ ...data });
  }

  function handleChangeInput(e) {
    const data = articleContent;
    const dataError = articleContentError;
    if (e.target.name === "title") {
      // const titlname = toTitleCase(e.target.value);
      const titlname = e.target.value;
      data.title = titlname;
      setmainTitle(titlname);
      if (e.target.value !== "") dataError.titleError = "";
    } else if (e.target.name === "runningTitle") {
      data.runningTitle = e.target.value;      
      const wordcount = (e.target.value.trim()).split(' ');      
      if (e.target.value !== "") dataError.runningTitleError = "";
      if (e.target.value !== "" && wordcount.length > 10) {
        dataError.runningTitleError = "A running title can be a maximum of 10 words in length. ";
      }else{
        dataError.runningTitleError = "";
      }
    } else if (e.target.name === "abstract") {
      data.abstract = e.target.value;
      if (e.target.value !== "") dataError.abstractError = "";
    }
    setarticleContent({ ...data });
    setarticleContentError({ ...dataError });
  }
  function handleChangeInputEditorTitle(e, k) {
    const data = editorTitleValue;
    const editorError = editorTitleValueError;
    data[k].val = e.target.value;
    seteditorTitleValue([...data]);
    editorError[k].val = "";
    seteditorTitleValueError([...editorError]);
  }

  function addMoreEditor() {
    const data = editorLength;
    const editorEditL = editorTitleEdit;
    const editorContent = editorContentValue;
    const editorTitle = editorTitleValue;
    const editorError = editorTitleValueError;
    data.push(editorLength.length);
    seteditorLength([...data]);
    editorEditL.push({ val: true });
    seteditorTitleEdit([...editorEditL]);
    editorContent.push({ val: "" });
    editorTitle.push({ val: "" });
    seteditorTitleValue([...editorTitle]);
    seteditorContentValue([...editorContent]);
    editorError.push({ val: "" });
    seteditorTitleValueError([...editorError]);
  }
  function removeEditor(key) {
    const editor = editorLength;
    const editorEditL = editorTitleEdit;
    const editorTitle = editorTitleValue;
    const editorContent = editorContentValue;
    const editorError = editorTitleValueError;
    if (editorEditL[key]) {
      editorEditL.splice(key, 1);
      seteditorTitleEdit([...editorEditL]);
    }
    if (editorError[key]) {
      editorError.splice(key, 1);
      seteditorTitleValueError([...editorError]);
    }
    if (editor[key]) {
      editor.splice(key, 1);
      const countedit = [];
      editor.forEach((element, k) => {
        countedit.push(k);
      });
      seteditorLength([...countedit]);
    }
    if (editorTitle[key]) {
      editorTitle.splice(key, 1);
      seteditorTitleValue([...editorTitle]);
    }
    if (editorContent[key]) {
      editorContent.splice(key, 1);

      seteditorContentValue([...editorContent]);
      getExtractImageFromContent(editorContent);
    }
  }
  function removeAuthorInputs(key) {
    const dataError = articleContentError;
    const data = authorAffiliationsCount;
    const details = articleContent;
    if (details.affiliationInstitute[key]) {
      details.affiliationInstitute.splice(key, 1);
    }
    if (details.affiliationDepartment[key]) {
      details.affiliationDepartment.splice(key, 1);
    }
    if (details.affiliationAuthorName[key]) {
      details.affiliationAuthorName.splice(key, 1);
    }
    if (details.affiliationTitle[key]) {
      details.affiliationTitle.splice(key, 1);
    }
    dataError.affiliationAuthorNameError[key] = "";
    dataError.affiliationDepartmentError[key] = "";
    dataError.affiliationTitleError[key] = "";
    dataError.affiliationInstituteError[key] = "";
    setarticleContentError({ ...dataError });
    setarticleContent({ ...details });
    data.splice(key, 1);
    let newatc = data;
    newatc = newatc.filter(function (_, index) {
      return newatc.hasOwnProperty(index);
    });
    setauthorAffiliationsCount([...data]);
  }

  const SimilarityCheck = async (e) => {
    e.preventDefault();
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const resp = await startSimilarityCheck({
        data: {
          similarityCheckId: unicheckData.similaritycheckId,
          userId: tokenVal?.id,
        },
        headers,
      });
      if (resp.status === 200) {
        setShowDownloadbtn(true);
        setUnicheckData({ ...unicheckData, url: resp.data.url });
        dispatch({ type: "SET_LOADER", payload: false });
      }
    } catch (err) {
      dispatch({ type: "SET_LOADER", payload: false });
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };

  const getSimillarityReport = async (e) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { similarityCheckId: simillarityResult.indexId },
      headers: headers,
    };
    downloadSimiliratyReport(queryParams);
  };
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const DestinationIndex = result.destination.index;
    removeFocusFromEditorTitle("", sourceIndex);
    removeFocusFromEditorTitle("", DestinationIndex);
    
    const editorTitle = editorTitleValue;
    const editorTitleSourceVal = editorTitle[sourceIndex].val;
    const editorTitleDestiationVal = editorTitle[DestinationIndex].val;
    editorTitle[sourceIndex].val = editorTitleDestiationVal;
    editorTitle[DestinationIndex].val = editorTitleSourceVal;
    seteditorTitleValue([...editorTitle]);

    const editorContent = editorContentValue;
    const editorContentSourceVal = editorContent[sourceIndex].val;
    const editorContentDestiationVal = editorContent[DestinationIndex].val;
    editorContent[sourceIndex].val = editorContentDestiationVal;
    editorContent[DestinationIndex].val = editorContentSourceVal;
    seteditorContentValue([...editorContent]);
  }
  function removeSupplimentFile(index) {
    const supplimentImge = supplimentImges;
    supplimentImge.splice(index, 1);
    setsupplimentImges([...supplimentImge]);
  }
  async function uploadSupplimentFile(e) {
    const file = e.target.files[0];
    const extension = file.name.split(".").pop();
    if (
      extension === "pdf" ||
      extension === "docx" ||
      extension === "doc" ||
      extension === "xlsx" ||
      extension === "pptx" ||
      extension === "rtf"
    ) {
      dispatch({ type: "SET_LOADER", payload: true });
      const formData = new FormData();
      formData.append("file", file);
      const fname = `${Date.now()}_${file.name}`;
      const uploadResp = await UploadMediaWithName(formData, `?fileName=${fname}`);
      dispatch({ type: "SET_LOADER", payload: false });
      if (uploadResp.status === 200) {
        const supplimentImge = supplimentImges;
        supplimentImge.push(uploadResp.data.url);
        setsupplimentImges([...supplimentImge]);
      } else {
        alert("s3 bucket upload error");
      }
    } else {
      setinvalidFilePopup(true);
    }
    e.target.value = "";
  }
  
  function saveAndPay(predata, type, tabno) {
    const { id } = jwt_decode(localStorage.usertoken);

    const comptab = completedTabs;
    const preDetails = preStepData;
    if (tabno === "0") {
      //disciplineType
      const payload = {
        userId: id,
        submissionDetail: {
          societyName: predata.societyName,
          journalType: predata.journalType,
          journalName: predata.journal,
          articleType: predata.articleType,
          subject: predata.subjectId,
        },
        validCouponCode: predata.validCouponCode,
        paymentBySociety: predata.paymentBySociety,
      };
      setarticleType(predata.articleType);
      if(predata.paymentBySociety.apcPayBySociety){
        var reviewData = {
          typeOfReview: predata.reviewType,
          isRecommendReviewers: predata.recommendReviwer,
          suggestedReviewers: [],
        }
        payload.reviewType = reviewData;
      }

      if(predata.specializationId && predata.specializationId!=='')
      payload.submissionDetail.specialization = predata.specializationId;
      if (predata.disciplineType !== "")
        payload.submissionDetail.disciplineType = predata.disciplineType;
      if (predata.secondarySpecialization !== "")
        payload.submissionDetail.secondarySpecialization =
          predata.secondarySpecialization;
      if (predata.secondarySubject !== "")
        payload.submissionDetail.secondarySubject = predata.secondarySubject;
      if (predata.secondaryJournalName !== "")
        payload.submissionDetail.secondaryJournalName =
          predata.secondaryJournalName;
      if (articleContent.id !== "") payload.articleId = articleContent.id;
      const saveData = { headers: headers, payload: payload };
      
      saveArticleToDraft(saveData);
      comptab.mySubmission = true;
      let discipline = "";
      if (predata.disciplineType !== "") discipline = predata.disciplineType;
      const submissionData = {
        submissionDetail: {
          secondarySpecialization: predata.secondarySpecialization,
          secondarySubject: predata.secondarySubject,
          secondaryJournalName: predata.secondaryJournalName,
          societyName: predata.societyName,
          articleType: { _id: predata.articleType },
          disciplineType: { _id: discipline },
          specialization: { _id: predata.specialization, specialization: predata.specialization },
          specializationId: { _id: predata.specializationId },
          subject: { _id: predata.subject, subjectName: predata.subject },

          subjectId: { _id: predata.subjectId },
          journalType: { _id: predata.journalType },
          journalName: predata.journal,
        },
      };
      preDetails.submissionDetail = submissionData.submissionDetail;
      setpreStepData({ ...preDetails });
    } else if (tabno === "2") {

      const payload = {
        userId: id,
        articleId: articleContent.id,
        reviewType: {
          typeOfReview: predata.reviewType,
          isRecommendReviewers: predata.recommendReviwer,
          suggestedReviewers: [],
        },
      };
      const suggestedReviewers = [];
      const storeReview = [];
      for (let i = 0; i < predata.name.length; i++) {
        const detl = {
          title: predata.title[i].val,
          fullName: predata.name[i].val,
          email: predata.email[i].val,
          affiliatedDepartment: "",
          affiliatedInstitude: predata.university[i].val,
          orcidId: predata.orcidId[i].val,
          specialization: predata.specialization[i].val,
          additionalComment: "",
        };
        if (predata.department[i] && predata.department[i].val !== "")
          detl.affiliatedDepartment = predata.department[i].val;
        if (
          predata.additionalComment[i] &&
          predata.additionalComment[i].val !== ""
        )
          detl.additionalComment = predata.additionalComment[i].val;
        if (predata.recommendReviwer === true) suggestedReviewers.push(detl);
        detl.specialization = { _id: predata.specialization[i].val };
        if (predata.recommendReviwer === true) storeReview.push(detl);
      }
      // console.log("suggestedReviewers",suggestedReviewers[0]?.fullName);
      // console.log('suggestedReviewers[0]?.fullName',suggestedReviewers[0]?.fullName)
      if (suggestedReviewers.length > 0 && suggestedReviewers[0]?.fullName != "") {
        payload.reviewType.suggestedReviewers = suggestedReviewers;
      }
    
      const queryParams = {
        payload: payload,
        headers,
      };
      saveArticleToDraft(queryParams);
      if(predata.validCouponCode){
        comptab.review = true;
      // handleRedirect('', `/manage-payment/${articleContent.id}`)
      }else{
        comptab.review = false;
        handleRedirect('', `/manage-payment/${articleContent.id}`)
      }
      
      const review = {
        reviewType: {
          typeOfReview: predata.reviewType,
          paymentDone: (user.nationality!=='withInIndia'?true:false),
          isRecommendReviewers: predata.recommendReviwer,
          suggestedReviewers: storeReview,
        },
      };

      preDetails.reviewType = review.reviewType;
      setpreStepData({ ...preDetails });
    } else if (tabno === "3") {
      const authors = [];

      for (let i = 0; i < predata.authorEmail.length; i++) {
        const detl = {
          title: predata.authorTitle[i].val,
          fullName: predata.authorname[i].val,
          email: predata.authorEmail[i].val,
          affiliatedDepartment: "",
          affiliatedInstitude: predata.authorUniversity[i].val,
          orcidId: predata.authorOrcidID[i].val,
        };
        if (
          predata.authorDepartment[i] &&
          predata.authorDepartment[i].val !== ""
        )
          detl.affiliatedDepartment = predata.authorDepartment[i].val;

        authors.push(detl);
      }

      const payload = {
        userId: id,
        articleId: articleContent.id,
        authorDetails: {
          author: authors,
          notThePrimaryAuthor: predata.isMePrimaryAuthor,
          contributionStatement: predata.contributionStatement,
        },
      };
      const queryParams = {
        payload: payload,
        headers,
      };
      saveArticleToDraft(queryParams);
      comptab.author = true;
      const authorDtl = {
        authorDetails: {
          contributionStatement: predata.contributionStatement,
          notThePrimaryAuthor: predata.isMePrimaryAuthor,
          _id: "7867",
          author: authors,
        },
      };
      preDetails.authorDtl = authorDtl.authorDetails;
      setpreStepData({ ...preDetails });
    } else if (tabno === "4") {
      const funder = [];
      const payload = {
        userId: id,
        articleId: articleContent.id,
        fundingDetails: {
          fundingInformation: predata.fundingInformation,
          noFundingRecevied: predata.noFundingRecevied,
          funder: funder,
        },
      };
      for (let i = 0; i < predata.fundingAgency.length; i++) {
        const detl = {
          NameOfFundingAgency: predata.fundingAgency[i].val,
          urlOfFundingAgency: predata.fundingAgencyUrl[i].val,
          grantAmount: predata.grantAmount[i].val,
          nameOfContactPerson: predata.name[i].val,
          emailId: predata.email[i].val,
          mobileNumber: predata.phone[i].val,
        };
        funder.push(detl);
      }
      if (predata.noFundingRecevied === false)
        payload.fundingDetails.funder = funder;

      const queryParams = {
        payload: payload,
        headers,
      };
      saveArticleToDraft(queryParams);
      comptab.funding = true;

      const fund = {
        fundingDetails: {
          _id: "dcfsd",
          funder: funder,
          fundingInformation: predata.fundingInformation,
          noFundingRecevied: predata.noFundingRecevied,
        },
      };
      preDetails.fundingDetails = fund.fundingDetails;
      setpreStepData({ ...preDetails });
    } else if (tabno === "5") {
      const payload = {
        userId: id,
        articleId: articleContent.id,
        allconditionCheck: predata.checkedCondition,
        statements: {
          commercial: {
            commercialOrfinancialConflictOfInterest:
              predata.commercialFinanceConflict,
            text: predata.conflictDetail,
          },
          AakashGangaJournal: {
            versionOfManuscript: predata.manuscriptVersion,
            text: predata.manuscriptVersionDetail,
          },
        },
      };
      const queryParams = {
        payload: payload,
        headers,
      };
      saveArticleToDraft(queryParams);
      comptab.statement = true;
      if(preStepData?.submissionDetail?.articleType?._id !== '664aed76ada7b4f743b760a9')
      {
      setshowprePage(false);
      }
      setshowprePage(false);
      
      // window.location.reload();
      const ststement = {
        statements: {
          AakashGangaJournal: {
            versionOfManuscript: predata.manuscriptVersion,
            text: predata.manuscriptVersionDetail,
          },
          commercial: {
            commercialOrfinancialConflictOfInterest:
              predata.commercialFinanceConflict,
            text: predata.conflictDetail,
          },
        },
      };
      preDetails.statements = ststement.statements;
      setpreStepData({ ...preDetails });
    } else if (tabno === "1" && preStepData?.submissionDetail?.articleType?._id === '664aed76ada7b4f743b760a9') {
      // console.log("reached");
      const payload = {
        userId: id,
        articleId: articleContent.id,
        allconditionCheck: predata.checkedCondition,
        conference: {
          conferenceName: predata.conferenceName,
          city: predata.city,
          country: predata.country,
          url: predata.url,
          date: predata.date,
          conferenceChairName: predata.conferenceChairName,
          conferenceChairiEmail: predata.conferenceChairiEmail,
          trackChairName: predata.trackChairName,
          trackChairEmail: predata.trackChairEmail,
        },
      };
      const queryParams = {
        payload: payload,
        headers,
      };

      const conferenc = {
        conference: {
          conferenceName: predata.conferenceName,
          city: predata.city,
          country: predata.country,
          url: predata.url,
          date: predata.date,
          conferenceChairName: predata.conferenceChairName,
          conferenceChairiEmail: predata.conferenceChairiEmail,
          trackChairName: predata.trackChairName,
          trackChairEmail: predata.trackChairEmail,
        },
      };
      saveArticleToDraft(queryParams);
      comptab.conference = true;
      preDetails.conference = conferenc.conference;
      setpreStepData({ ...preDetails });
      // setshowprePage(false);
      // window.location.reload();
      // console.log("reached");
    }
    setcompletedTabs({ ...comptab });
    if (type === 1) setsavedDetailsPupup(true);
  }
  
  function getNameFromUrl(url) {
    let name = '';
    const pieces = url.split(/[\s/]+/)
    const last = pieces[pieces.length - 1]
    if (last) {
      const dtl = last.split('_');
      if (dtl[1])
        name = dtl[1].replace(/%20/g, " ")
      else
        name = last.replace(/%20/g, " ")
    }

    return name
  }
  function manageProfanitySuccessPopup() {
    setprofanitySuccess(false)
    dispatch({ type: "SET_LOADER", payload: true });
    setTimeout(() => {
      dispatch({ type: "SET_LOADER", payload: false });
      setsavedDraftArticle(true);
    }, "2000");
  }
   
  function addCitation()
  {
    const data={publishPopup:false,totalLength:[0],url:'',isMulti:false,year:'',artTitle:'',urlError:'',yearError:'',fname:[{val:''}],lname:[{val:''}],fnameError:[],lnameError:[],artTitleError:[]};
    setcitationDetails(data)
    setcitationPopup(true)
  }
  function removeCitationAuthor(key)
  {
    const citation=citationDetails
    if (citation.fname[key]) {
      citation.fname.splice(key, 1);
    }
    if (citation.lname[key]) {
      citation.lname.splice(key, 1);
    }
    if (citation.fnameError[key]) {
      citation.fnameError.splice(key, 1);
    }
    if (citation.lnameError[key]) {
      citation.lnameError.splice(key, 1);
    }
    citation.totalLength.splice(key, 1);
    setcitationDetails({...citation})
  }
  function handleMultiCitation()
  {
    const citation=citationDetails
    const totlCitation=citation.totalLength
    totlCitation.push(citation.totalLength.length);
    citation.fname.push({ val: "" });
    citation.lname.push({ val: "" });
    citation.totalLength=totlCitation;
    setcitationDetails({...citation})
  }
  function handleMultiAuthorCheckbox(e)
  {
    const citation=citationDetails
    if(e.target.checked) 
    citation.isMulti=true
    else
    citation.isMulti=false
    setcitationDetails({...citation})
  }
  function handleCitationInput(e,index, type)
  {
    const citation=citationDetails
    if(type==='fname')
    {
      citation.fname[index].val= e.target.value
      if(citation.fnameError[index])
      citation.fnameError[index]=''
    }
    else if(type==='lname')
    {
      citation.lname[index].val= e.target.value
      if(citation.lnameError[index])
      citation.lnameError[index]=''
    }
    else if(type==='url')
    {
      citation.url= e.target.value
      citation.urlError=''
    }
    else if(type==='year')
    {
      citation.year= e.target.value
      citation.yearError=''
    }
    else if(type==='artTitle')
    {
      citation.artTitle= e.target.value
      citation.artTitleError=''
    }
    setcitationDetails({...citation})
  }
  function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  function genrateCitaion()
  {
    // console.log(citationDetails);
    const citation=citationDetails;
    let error=false;
    for (let i = 0; i < citation.totalLength.length; i++) {
      if(citation.fname[i].val==='')
      citation.fnameError[i]='Please enter first name'
      if(citation.lname[i].val==='')
      citation.lnameError[i]='Please enter last name'
    }
    if(citation.url==='')
    {
      citation.urlError='Please enter url'
      error=true;
    }
    else if( checkUrl(citation.url)===false)
    {
      error=true;
      citation.urlError='Please enter correct url'
    }
    if(citation.year==='')
    {
      citation.yearError='Please enter year of publication'
      error=true;
    }
    if(citation.artTitle==='')
    {
      citation.artTitleError='Please enter Article Title'
      error=true;
    }
   
    if(error===false)
    {
      citation.publishPopup=true;
      setcitationPopup(false)
      
    }
    setcitationDetails({...citation})

  }
  function calculateCitaions()
  {
    let count=1;
    if (refranceAck.refrances !== "") {

      const matches = [];

      refranceAck.refrances.replace(/[^<]*(<a href="([^"]+)">([^<]+)<\/a>)/g, function () {
        matches.push(Array.prototype.slice.call(arguments, 1, 4))
     });
     
     count=matches.length+1
    }
    
   return count
  }

  function copyCitationDetails()
  {
    
    const citation=citationDetails;
    let lnames = citation.lname.map(x => x.val).join(", ");
    let fnames = citation.fname.map(x => x.val).join(", ");
    const uid=uuidv4();
    const content=`<div id="linkTo${uid}" class='generatedCitation'><a href="#${uid}">(${lnames}, ${citation.year})</a></div>Extract<div id="${uid}" class='generatedCitationLink'><span>number </span><a href="${citation.url}IDTAG=${uid}">${citation.url}</a><br></br></div>`
    copy(content)
    var newContent = `${refranceAck.refrances} <p id="${uid}" class='generatedCitationLink'><b>${lnames}, ${fnames}, ${citation.artTitle}, ${citation.year}, </b><a href="${citation.url}IDTAG=${uid}">${citation.url}</a></p>`
    refranceAck.refrances = newContent;
    setrefranceAck({...refranceAck});
    // console.log(newContent);
    return toast.success("Copied");
    
    
  }
  function closeCitationPublish()
  {
    const citation=citationDetails;
    citation.publishPopup=false;
    setcitationDetails({...citation})
  }
  function manageCiatation(citation)
  {
    const citationLength=calculateCitaions()
    let c = citation.replace("number", citationLength+'. ');
    // refranceRef.current.citationInRefrance(c);
  }

  function getRatingValue(rating) 
  {
    const feedback=feedbackPupup;
    feedback.starVal=rating
    if(rating===0)
    feedback.rateError='Please provide rating';
    else
    feedback.rateError='';
    setfeedbackPupup({...feedback})
  }

  function saveFeedBackDetails()
  {
    const feedback=feedbackPupup;
    if(feedbackPupup.starVal===0)
    {
      feedback.rateError='Please provide rating';
      setfeedbackPupup({...feedback})
    }
    else{
      const { id } = jwt_decode(localStorage.usertoken);
      const payload={ "userId":id,"articleId":articleContent.id,"rating":feedback.starVal,"feedbackText":feedback.feedback}
      const queryParams = {
        payload: payload,
        headers,
      };
      saveAuthorFeedback(queryParams);
    }
  }
  const sucessHandlerFeedback = (details) => {
    if (details.status === 200) {
      const feedback=feedbackPupup;
      feedback.starVal=0
      feedback.rateError='';
      feedback.openPopup=false;
      feedback.successPopup=true;
      setfeedbackPupup({...feedback})
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };
  
 
  
 const { mutate: saveAuthorFeedback } = SaveAuthorsFeedback(sucessHandlerFeedback);
  
// console.log('preStepData',completedTabs)

  return ( 
    <div
      className={
        preStepData.plagirismCheck !== "notDone"
          ? "editor-view-only manageEditorBox"
          : "manageEditorBox"
      }
    >
     
      <div className="editorHeadMain">
      <div className="innerMerginHead">
        <div className="editorHead">
          <div className="editorHeadLeft">
            {isUpdateNewVersion===false && <h2>{articleID === "" ? "Submit" : "Submit"} Article</h2>}
            {isUpdateNewVersion===true && <h2>New Article Version</h2>}
          </div>

          <div className="editCenterWrap">
            <b className="blinkText">
              {mendatoryAlert !== "" && (
                <>
                  <div className="customTool">
                   
                        {/* <img
                          className="pe-cursor ps-1"
                          src={CheckPri}
                          alt="no img"
                        />
                        */}
                  </div>
                  <i className="blink_me">{mendatoryAlert}</i>
                </>
              )}
              {blinkError !== "" && (
                <>
                  <div className="customTool">
                    {["bottom"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Popover>
                            <Popover.Body>
                              <div className="popoverToolWrap">
                                <p>Help</p>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <img
                          className="pe-cursor ps-1"
                          src={CheckPri}
                          alt="no img"
                        />
                      </OverlayTrigger>
                    ))}
                  </div>
                  <i onClick={() => setprofanityAlert({ isOPen: profanityWordsFound.length > 0 ? true : false, type: profanityWordsFound.length > 0 ? 1 : 0 })} className={profanityWordsFound.length > 0 ? ' profanityBlink' : ''}>{blinkError}</i>
                </>
              )}

              {(simillarityResult.percent > 15 && mendatoryAlert === '' && blinkError!=='') && (
                <>
                  <img
                    className="pe-cursor ps-1"
                    src={downloadImg}
                    onClick={() => getSimillarityReport()}
                    alt="Download"
                  />
                </>
              )}
            </b>
            {preStepData.plagirismCheck === "Done" &&
              preStepData.status === "draft" && (
                <>
                  <div className="customTool">
                    {["bottom"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Popover>
                            <Popover.Body>
                              <div className="popoverToolWrap">
                                <p>
                                  The profanity check is
                                  an important element for all articles
                                  published on the Aakashganga platform. This is
                                  done to ensure professionalism, clarity, and
                                  appropriateness of language throughout the
                                  document. </p>
                                 <p className="mt-3 mb-0"> This process involves identifying
                                  and removing any offensive or inappropriate
                                  language, including profanity, vulgar
                                  expressions, or offensive remarks.
                                </p>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <img
                          className="pe-cursor ps-1"
                          src={active_info}
                          alt="no img"
                        />
                      </OverlayTrigger>
                    ))}
                  </div>
                  <div className="checkTextBox">
                    <b className="blinkText" >
                      Profanity Check{" "}
                      <img
                        className="pe-cursor ps-1"
                        src={tickChkImg}
                        alt="Download"
                      />{" "}
                    </b>
                    <b className="blinkText" onClick={() => getSimillarityReport()}>
                      Similarity Index Check
                      <img
                        className="pe-cursor ps-1"
                        src={tickChkImg}
                        alt="Download"
                      />
                    </b>
                    <img
                      onClick={() => getSimillarityReport()}
                      className="pe-cursor ps-1"
                      src={downloadImg}
                      alt="Download"
                    />
                  </div>
                </>
              )}
          </div>

          <div className="text-right">
           
            <div className="articleRightBtn submisionHead">
              <span className="button-link">
              Author Submission Guidelines
              </span>
            </div>
           
          </div>
        </div>
        </div>
      </div>

      <div className="manageEditorInner">
        <div className="editNoteWrap">
          <div className="editorAction">
            <a
              onClick={() => getDetailsInfo()}
              className={showprePage === true ? "active" : ""}
            >
             Article Submission Details
            </a>
            <a
              onClick={() =>
                setshowprePage(completedTabs.conference === true ? false : true)
              }
              className={completedTabs.conference === true && preStepData?.reviewType?.paymentDone == true && completedTabs.statement === true ? showprePage === false ? "active" : "" : 'opacity-5'}
            >
            Article Editor
            </a>
            <div className="customTool">
              {["bottom"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <div className="popoverToolWrap">
                        
                        <p>The article editor will be live after all the sections are completed <br /> and registration fee of {user?.nationality === "outSideIndia" ? "USD 50":'INR 1000'} is received.</p>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <img
                    className="pe-cursor ps-1"
                    src={active_info}
                    alt="no img"
                  />
                </OverlayTrigger>
              ))}
            </div>

            
          </div>
          <div className="noteBox">
            <span>Note:</span>The article limit is 4,000-10,000 words.
            <div className="customTool">
              {["bottom"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <div className="popoverToolWrap">
                          <p>
                          The 4,000-10,000 word limit does not include Introduction, Abstract, Acknowledgements, Keywords and References.
                          </p>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <img
                    className="pe-cursor ps-1"
                    src={active_info}
                    alt="no img"
                  />
                </OverlayTrigger>
              ))}
            </div>
          </div>
        </div>

        {/* ADD ARTICLE postValueStatus prePaymentStatus */}
        {showprePage === true &&
          isEdited === false &&
          masterDataLoaded === true && APCData.length>0 && (
            <MySubmission
              submissionRef={submissionRef}
              subjects={subjectList}
              subjectSpecializationList={subjectSpecializationList}
              articleTypeList={articleTypeList}
              prePaymentStatus={prePaymentStatus}
              saveAndPay={saveAndPay}
              journalMaster={gernalMaster}
              journalType={gernalType}
              completedTabs={completedTabs}
              specializationList={specializationList}
              desciplineMaster={desciplineMaster}
              preStepData={preStepData}
              APCData={APCData}
              isUpdateNewVersion={isUpdateNewVersion}
            />
          )}
        {/* ADD ARTICLE END */}

        {showprePage === false && (
          <div className="personal-details" id="article-editor__comp">
           
            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="commanBox mb-25 pb-0">
                  <div
                    className={
                      isOpenTableContent === true
                        ? "dragWrapShow active"
                        : "dragWrapShow"
                    }
                  >
                    <div className="dragWrapHead">
                      <h2>
                        Article structure{" "}
                        <div className="customTool">
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <div className="popoverToolWrap">
                                      <p>
                                        Please use the elements below to change
                                        <br />
                                        the sequence of the article sections as
                                        <br />
                                        you may desire.
                                      </p>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <img
                                className="pe-cursor ps-1"
                                src={active_info}
                                alt="no img"
                              />
                            </OverlayTrigger>
                          ))}
                        </div>
                      </h2>
                      <b
                        onClick={() =>
                          setisOpenTableContent(!isOpenTableContent)
                        }
                      />
                    </div>
                    <div className="dragWrapContent">
                      <span>Title</span>
                      <span>Running Title</span>
                      <span>Abstract</span>
                      <span>Introduction</span>
                      <ul>
                        <DragDropContext onDragEnd={(detl) => onDragEnd(detl)}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {editorLength.map((key, index) => (
                                  <>
                                    {key > 0 && (
                                      <div id={`editordiv${index}`}>
                                        <Draggable
                                          key={key}
                                          draggableId={`dragid${index}`}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <li
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <span>
                                                {editorTitleEdit[key]?.val ===
                                                  false && (
                                                    <>
                                                      <i
                                                        onClick={() => {
                                                          editInputLable(key);
                                                        }}
                                                      />
                                                      {editorTitleValue[key]
                                                        ?.val === ""
                                                        ? ""
                                                        : editorTitleValue[key]
                                                          ?.val}
                                                    </>
                                                  )}{" "}

                                                {editorTitleEdit[key]?.val ===
                                                  true && (
                                                    <div className="editTime">
                                                      <Input
                                                        errors={
                                                          editorTitleValueError[
                                                            key
                                                          ] &&
                                                            editorTitleValueError[
                                                              key
                                                            ].val !== ""
                                                            ? editorTitleValueError[
                                                              key
                                                            ].val
                                                            : ""
                                                        }
                                                        name={`editorTitle${key}`}
                                                        handleChange={(e) =>
                                                          handleChangeInputEditorTitle(
                                                            e,
                                                            key
                                                          )
                                                        }
                                                        handleBlur={(e) =>
                                                          removeFocusFromEditorTitle(
                                                            e,
                                                            key
                                                          )
                                                        }
                                                        className="fieldForm"
                                                        type="text"
                                                        required={"required"}
                                                        value={
                                                          editorTitleValue[key]
                                                            ?.val
                                                        }
                                                        label=""
                                                        readOnly={
                                                          editorTitleValue[key]
                                                            ?.val === "References"
                                                            ? true
                                                            : false
                                                        }
                                                        placeholder="Enter Title"

                                                      />
                                                      <div className="saveEdit" onClick={(e) =>
                                                        removeFocusFromEditorTitle(
                                                          e,
                                                          key
                                                        )
                                                      }>Done</div>
                                                    </div>
                                                  )}

                                              </span>
                                              <div class="dragArea"></div>
                                            </li>
                                          )}
                                        </Draggable>
                                      </div>
                                    )}
                                  </>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </ul>

                      <span>References</span>
                      <span>Acknowledgments</span>
                    </div>
                  </div>
                </div>
                <div className="commanBox mb-25">
                 
                  <div className="fieldMore d-inline-block w-100 pt-0 pb-2 editMore">
                    <span>
                      Keywords{" "}
                      <small className="small-Text">
                        (<b>Minimum limit:</b> 5)</small>
                       
                    </span>
                    <a className="addKey" onClick={() => addKeyWord("keyword")}>
                      + Add{" "}
                    </a>
                  </div>

                  <div className="fieldWrap">
                    <input
                      title=""
                      ref={keyRef}
                      className="fieldForm"
                      placeholder="Enter Keywords"
                      type="text"
                      name=""
                     
                      onKeyDown={(e) => {
                        if (e.code === 'Enter')
                          addKeyWord('keyword')
                      }}

                    />
                  </div>
                  {articleContentError.keywordsError !== "" && (
                    <div className="label-error">
                      {articleContentError.keywordsError}
                    </div>
                  )}
                  <div className={articleContent.keywords.length > 0 ? 'keyBox tagsNewWrap' : 'keyBox'}>
                    {articleContent.keywords.map((keyword, index) => (
                      <span key={keyword} className="t-tag">
                        {keyword}
                        <i
                          className="imgClose"
                          onClick={() => removeKeyWord("keyword", index)}
                        ></i>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="commanBox mb-25">
                  <div className="fieldMore pt-0 pb-0 editMore">
                    <span className="pb-2">Abbreviations</span>
                    <a
                      className="addKey pb-2"
                      onClick={() => addKeyWord("abbreviations")}
                    >
                      + Add
                    </a>
                  </div>

                  <div className="abbreviationWrap">
                    <div className="fieldWrap">
                      <input
                        title=""
                        ref={abbreviationsRef}
                        className="fieldForm"
                        placeholder="Abbr."
                        type="text"
                        name=""
                        required
                        onKeyDown={(e) => {
                          if (e.code === 'Enter')
                            addKeyWord("abbreviations")
                        }}
                      />
                    </div>
                    <div className="fieldWrap">
                      <input
                        title=""
                        ref={abbreviationsFullRef}
                        className="fieldForm"
                        placeholder="Full Form"
                        type="text"
                        name=""
                        required
                        onKeyDown={(e) => {
                          if (e.code === 'Enter')
                            addKeyWord("abbreviations")
                        }}
                      />
                    </div>
                  </div>


                  





                  <div className={articleContent.abbreviations.length > 0 ? 'keyBox w-100 tagsNewWrap' : 'keyBox w-100'}>
                    {articleContent.abbreviations.map((abbreviation, index) => (
                      <span key={abbreviation} className="t-tag abbreView">
                        <b>{abbreviation}</b>
                        <b>{articleContent.abbreviationsFull[index]}</b>
                        <i
                          className="imgClose"
                          onClick={() => removeKeyWord("abbreviations", index)}
                        ></i>
                      </span>
                    ))}
                  </div>
                </div>

                <div className="commanBox mb-25 addCitation">
                <span className="tagTitle mb-0">
                Insert Citations
                    <div className="customTool top-3">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p>Proper citation is essential for acknowledging the work of other researchers and ensuring the integrity and credibility of your own research.</p>
                                  <p className="mt-3">Use this feature to cite any article you have used in your research. The button opens up a simple screen, which asks for elementary details of the author(s). You also need to copy and paste the URL or DOI of the same article in the indicated field. The system will automatically, generate the in-text citation, and place the URL/DOI in the references section. Note: If you delete the in-text citation, the referenced URL will still remain in the references section of your article.</p>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                    <label className="fileBox" onClick={addCitation}>
                       
                      CREATE
                    </label>
                  </span>
 
                </div>

                <div className="commanBox mb-25">
                  <span className="tagTitle">
                  Images and Figures
                    <div className="customTool top-3">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p className="mb-3">In order to enhance the impact and understanding of your work, please incorporate relevant images and figures into the designated section of your document.</p>
                                  <p><b>Guidelines</b>:</p>
                                  <p><b>Placement</b>: Please ensure that the images and figures are inserted on the left side of the editor screen within the specified section. This will maintain consistency and aid in seamless navigation.</p>
                                  <p><b>Relevance</b>: Select images and figures that directly correlate with the content of the section. Visual aids should enhance the understanding of your research and provide valuable insights.</p>
                                  <p><b>Captioning</b>: Each image or figure should be accompanied by a clear and concise caption. The caption should describe the content of the visual and its significance in relation to the section's topic.</p>
                                  <p><b>Formatting</b>: Ensure that the images and figures are appropriately sized to maintain readability.</p>
                                  <p><b>Source Attribution</b>: If the images or figures are sourced from external references, please provide proper attribution, including the source title, author, publication, and publication date.</p>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </span>
                  
                  <div className="imgShowBox">
                    {uploadeImages?.length > 0 ? 
                    uploadeImages.map((src, index) => (
                      <b
                        key={src}
                        style={{
                          backgroundImage: `url(${src})`,
                        }}
                      ></b>
                    )) : (
                      <div className="fieldWrap">
                        <p>
                          <span>Nothing uploaded yet</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="commanBox mb-25">
                  <span className="tagTitle">
                    Supplementary Material
                    <div className="customTool">
                                                {['bottom'].map((placement) => (
                                                    <OverlayTrigger 
                                                    key={placement}
                                                    placement={placement}
                                                    overlay={
                                                        <Popover>
                                                        <Popover.Body>
                                                        <div className="popoverToolWrap"> 
                                                        <p>You can upload PDF, MS Word, Excel, PPT, Data and RTF files in this section.</p>
                                                            </div>
                                                        </Popover.Body>
                                                        </Popover>
                                                    }
                                                    >
                                                    <img
                                                        className="pe-cursor ps-1"
                                                        src={active_info}
                                                        alt="no img"
                                                        /> 
                                                    </OverlayTrigger>
                                                ))}
                                            </div>
                    <label className="fileBox">
                      <input type="file" onChange={uploadSupplimentFile} />
                      UPLOAD
                    </label>
                  </span>
                  {supplimentImges.length === 0 && (
                    <div className="fieldWrap">
                      <p>Nothing uploaded yet</p>
                    </div>
                  )}
                  {supplimentImges.map((img, index) => (
                    <div className="docShowBox suppleBox" key={`mat${index}`}>
                      <div className="docShowList">
                        <a >
                          <span>{getNameFromUrl(img)}</span>

                        </a>
                        
                        <i
                          className="imgClose"
                          onClick={() => removeSupplimentFile(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="commanBox mb-25">
                  <div className="fieldWrap">
                    <label className="labelFormTwo">
                      Title {/*  <div className="supTag">Mandatory</div> */}
                    </label>

                    <Input
                      errors={articleContentError.titleError}
                      name="title"
                      handleChange={handleChangeInput}
                      className="fieldForm"
                      type="text"
                      required={"required"}
                      value={mainTitle}
                      label=""
                      placeholder="Click to add text"
                      readOnly={isForRevision || isUpdateNewVersion} 
                    />
                  </div>
                </div>

                <div className="commanBox mb-25">
                  <div className="fieldWrap">
                    <label className="labelFormTwo word10">
                      Running Title <small>
                        (<b>Word limit:</b> 10)
                      </small>
                      <div className="customTool">
                        {["bottom"].map((placement) => (
                          <OverlayTrigger
                            key={placement}
                            placement={placement}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <div className="popoverToolWrap">
                                    <p>
                                      The running title will be displayed at the
                                      top of each PDF page of an article.
                                    </p>
                                    <p className="mb-0"> A running title can be a maximum of 10 words
                                      in length.</p>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <img
                              className="pe-cursor ps-1"
                              src={active_info}
                              alt="no img"
                            />
                          </OverlayTrigger>
                        ))}
                      </div>
                    </label>

                    <Input
                      errors={articleContentError.runningTitleError}
                      name="runningTitle"
                      handleChange={handleChangeInput}
                      className="fieldForm"
                      type="text"
                      required={"required"}
                      value={articleContent?.runningTitle}
                      label=""
                      placeholder="Click to add text"
                    />
                  </div>
                </div>
                <div className="commanBox mb-25">
                  <div className="fieldWrap">
                    <label className="labelFormTwo word50">
                      Abstract <small>
                        (<b>Word limit:</b> 500)
                      </small>
                      <div className="customTool">
                        {["bottom"].map((placement) => (
                          <OverlayTrigger
                            key={placement}
                            placement={placement}
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <div className="popoverToolWrap">
                                    <p>
                                      Please note that your
                                      abstract should not exceed 500 words. We
                                      recommend that you carefully provide a
                                      concise and accurate summary of your
                                      research, highlighting its significance,
                                      methods, results, conclusions, and others.
                                      A well-written abstract captures the
                                      interest of readers, increase the
                                      visibility of your work, and conveys the
                                      importance of their research findings to
                                      the academic community.
                                    </p>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <img
                              className="pe-cursor ps-1"
                              src={active_info}
                              alt="no img"
                            />
                          </OverlayTrigger>
                        ))}
                      </div>
                    </label>
                    <Textarea
                      name="abstract"
                      rows="5"
                      className="form-control grey-input title"
                      placeholder="Click to add text"
                      value={articleContent?.abstract}
                      onChange={(e) => {
                        handleChangeInput(e);
                      }}
                    />
                    {articleContentError.abstractError !== "" && (
                      <div className="label-error">
                        {articleContentError.abstractError}
                      </div>
                    )}
                  </div>
                </div>

                <Grammarly
                  clientId={gramrlyClientID}
                  config={{
                    documentDialect: "british",
                    activation: "immediate",
                  }}
                >
                  {editorLength.map((key, index) => (
                    <>
                      {" "}
                      {preStepData.plagirismCheck === "Done" && checkEmptyArticle(editorContentValue[key]?.val)!=='' &&
                        (preStepData.status === "draft" || preStepData.status === "approved") && (
                          <ContentDetails
                            pagename="previewArticle"
                            details={{
                              title: editorTitleValue[key]?.val,
                              desc: editorContentValue[key]?.val,
                            }}
                          />
                        )}
                      {preStepData.plagirismCheck === "notDone" &&
                        preStepData.status === "draft" && (
                          <div id={`editordiv${index}`} className="addSctionEditor">
                            <div className="commanBox mb-25">
                              {key > 5 &&
                                (editorTitleValue[key]?.val !==
                                  "Introduction" ||
                                  editorTitleValue[key]?.val !==
                                  "Abstract") && (
                                  <i
                                    className="imgClose"
                                    onClick={() => removeEditor(index)}
                                  ></i>
                                )}
                              <div className="fieldWrap">
                                {editorTitleValue[key]?.val === "Abstract" && (
                                  <label className="labelFormTwo">
                                    Abstract
                                    <small>
                                      (word limit - 500 words){" "}
                                    </small>{" "}
                                    <div className="supTag">Mandatory</div>
                                  </label>
                                )}
                                {editorTitleValue[key]?.val ===
                                  "Introduction" && (
                                    <label className="labelFormTwo">
                                      Introduction{" "}
                                      <div className="customTool manageInto">
                                        {["bottom"].map((placement) => (
                                          <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                              <Popover>
                                                <Popover.Body>
                                                  <div className="popoverToolWrap">
                                                    <p>

                                                      The introduction section of a research paper plays a vital role in setting the tone, context, and purpose of the study. It serves as a bridge between the reader and the research, providing essential information and establishing the foundation for the rest of the paper. In this context, the introduction can be seen as the "gateway" to the research, guiding readers into the topic and capturing their interest.
                                                    </p>
                                                    <br />
                                                    <p>It provides background information, presents the problem statement or research question(s), incorporates a literature review, outlines the methodology (if applicable), and offers an overview of the paper's structure. By effectively addressing these elements, the introduction engages readers, clarifies the purpose of the research, and establishes its relevance within the academic community.</p>
                                                  </div>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <img
                                              className="pe-cursor ps-1"
                                              src={active_info}
                                              alt="no img"
                                            />
                                          </OverlayTrigger>
                                        ))}
                                      </div>
                                    </label>
                                  )}
                                {editorTitleValue[key]?.val !==
                                  "Introduction" &&
                                  editorTitleValue[key]?.val !== "Abstract" && (
                                    <div  >
                                      {editorTitleEdit[key]?.val === true && (
                                        <div className="edtInputEditor">
                                          <Input
                                            errors={
                                              editorTitleValueError[key] &&
                                                editorTitleValueError[key].val !==
                                                ""
                                                ? editorTitleValueError[key].val
                                                : ""
                                            }
                                            name={`editorTitle${key}`}
                                            handleChange={(e) =>
                                              handleChangeInputEditorTitle(e, key)
                                            }
                                            className="fieldForm"
                                            type="text"
                                            required={"required"}
                                            value={editorTitleValue[key]?.val}
                                            label=""
                                            readOnly={
                                              editorTitleValue[key]?.val ===
                                                "References"
                                                ? true
                                                : false
                                            }
                                            placeholder="Enter Title"
                                          />
                                          <div className="edtDone" onClick={(e) => {
                                            removeFocusFromEditorTitle(
                                              e,
                                              key
                                            )
                                          }}>Done</div>
                                        </div>
                                      )}
                                      {editorTitleEdit[key]?.val === false && (
                                        <div className="editEditorLabel">
                                          <b>{editorTitleValue[key]?.val}</b>
                                          <i
                                            onClick={() => {
                                              editInputLable(key);
                                            }}
                                          ></i>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                <div className="editorWrap">
                                  {/* <GrammarlyEditorPlugin> */}
                                  <SunRichText
                                    name={editorTitleValue[key]?.val}
                                    value={editorContentValue[key]?.val}
                                    handleChange={(content, type) => {
                                      editorContent(content, type, key);
                                    }}
                                    uploadedImg={(imgUrl) => {
                                      editorUploadedImg(
                                        imgUrl,
                                        editorTitleValue[key]
                                      );
                                    }}
                                    manageCiatation={(citation) => {
                                      manageCiatation(citation);
                                    }}
                                    refranceRef={el => (articleContentRefs[key] = el)}
                                  />
                                  {/* </GrammarlyEditorPlugin> */}
                                  {editorTitleValue[key]?.val === "Abstract" &&
                                    articleContentError.abstractError !==
                                    "" && (
                                      <div className="label-error">
                                        {articleContentError.abstractError}
                                      </div>
                                    )}
                                  {editorTitleValue[key]?.val ===
                                    "Introduction" &&
                                    articleContentError.introductionError !==
                                    "" && (
                                      <div className="label-error">
                                        {articleContentError.introductionError}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  ))}

                  <div className="fieldMore pt-0 pb-0 text-center mb-25">
                    <a onClick={() => addMoreEditor()}>+ ADD NEW SECTION</a>
                  </div>
                  <div>
                    
                      {preStepData.plagirismCheck === "Done" && checkEmptyArticle(refranceAck.refrances)!=='' &&
                        (preStepData.status === "draft" || preStepData.status === "approved") && (
                          <div className="commanBox mb-25">
                          <div className="fieldWrap"><ContentDetails
                            pagename="previewArticle"
                            details={{
                              title: "References",
                              desc: refranceAck.refrances,
                            }}
                          /> </div> </div>
                        )}
                      {preStepData.plagirismCheck === "notDone" &&
                        preStepData.status === "draft" && (
                          <>
                          <div className="commanBox mb-25">
                             <div className="fieldWrap">
                            <label className="labelFormTwo">References </label>
                            <div className="editorWrap" id="refranceEditr">
                              {/* <GrammarlyEditorPlugin> */}
                              <SunRichText
                                name="References"
                                value={refranceAck.refrances}
                                handleChange={(content, type) => {
                                  editorContent(content, type, "references");
                                }}
                                uploadedImg={(imgUrl) => {
                                  editorUploadedImg(imgUrl, "References");
                                }}
                                manageCiatation={(citation) => {
                                  manageCiatation(citation);
                                }}
                                refranceRef={refranceRef}
                              />
                              {/* </GrammarlyEditorPlugin> */}
                            </div> </div> </div>
                          </>
                        )}
                   </div> 
                  <div>
                    
                      {preStepData.plagirismCheck === "Done" && checkEmptyArticle(refranceAck.acknowledgements)!=='' &&
                        (preStepData.status === "draft" || preStepData.status === "approved") && (
                          <div className="commanBox mb-25">
                             <div className="fieldWrap">
                          <ContentDetails
                            pagename="previewArticle"
                            details={{
                              title: "Acknowledgements",
                              desc: refranceAck.acknowledgements,
                            }}
                          /></div></div>
                        )}
                      {preStepData.plagirismCheck === "notDone" &&
                        preStepData.status === "draft" && (
                          <>
                          <div className="commanBox mb-25">
                             <div className="fieldWrap">
                            <label className="labelFormTwo">
                              Acknowledgements{" "}
                            </label>
                            <div className="editorWrap">
                              {/* <GrammarlyEditorPlugin> */}
                              <SunRichText
                                name="Acknowledgements"
                                value={refranceAck.acknowledgements}
                                handleChange={(content, type) => {
                                  editorContent(
                                    content,
                                    type,
                                    "acknowledgements"
                                  );
                                }}
                                uploadedImg={(imgUrl) => {
                                  editorUploadedImg(imgUrl, "Acknowledgements");
                                }}
                                manageCiatation={(citation) => {
                                  manageCiatation(citation);
                                }}
                                refranceRef={acknowledgementRef}
                              />
                              {/* </GrammarlyEditorPlugin> */}
                            </div>{" "}</div></div>
                          </>
                        )}
                    
                  </div>

                </Grammarly>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 my-3 m-auto"></div>
            </div>
          </div>
        )}
        {showprePage === false && (
          <div className="confirmFooter confirmFooterFix">
            <div className="confirmContent">
            {preStepData?.submissionDetail?.journalType?.journalType === "Society Journal" && preStepData?.paymentBySociety?.apcPayBySociety ?<>
  <h3> APC PAID BY THE SOCIETY </h3>
  <div className="questionBox reddot">
                <span>
                Registration payment made by the society
                
                </span>
                <span>
                Final payment will be made after the article is approved by the editors.
                </span>
              </div>
  </>:(<>
              {(APCData.length>0 && isUpdateNewVersion===false) && <h3>
                REMAINING ARTICLE PROCESSING CHARGE: <b>{user?.nationality === "outSideIndia" ? "USD":'INR'} {preStepData.reviewType?.typeOfReview ===
                                "pre-publication peer review only"?APCData[0].introductoryPrice - APCData[2]?.introductoryPrice : APCData[1].introductoryPrice - APCData[2]?.introductoryPrice}</b>
              </h3>}
              
              {isUpdateNewVersion===false && <div className="questionBox reddot">
                <span>
                  You may also be eligible for additional grants and discounts
                </span>
                <span>
                  All offers will be applied after the article is accepted for
                  publication
                </span>
              </div>}
              
              </>)}
            </div>
            <div className="confirmBtn">
              {preStepData.plagirismCheck === "notDone" &&
                preStepData.status === "draft" && (
                  <span
                    className="btn-link-active"
                    onClick={() => submitAsDraft()}
                  >
                    Save
                  </span>
                )}
              <span
                className={
                  articleContent.title === "" ||
                    articleContent.abstract === "" ||
                    editorContentValue[0].val?.replace(/(<([^>]+)>)/gi, "") === ""
                    ? "btn-link-active opacity5"
                    : "btn-link-active"
                }
                onClick={() => previewArticle()}
              >
                Preview Article{" "}
              </span>
              {preStepData.plagirismCheck === "notDone" &&
                preStepData.status === "draft" && (
                  <button
                    className="button button-primary"
                    type="button"
                    onClick={() => {
                      submitArticleWithGrammar()
                      setfinalSubmitds("adminctrl");
                    }}
                  >
                    Submit Article
                  </button>
                )}
              {preStepData.plagirismCheck === "Done" &&
                preStepData.status === "draft" && (
                  <button
                    className="button button-primary"
                    type="button"
                    onClick={() => setfinalSubmitPupup(true)}
                  >
                    PROCEED TO SUBMISSION
                  </button>
                )}
                {preStepData.plagirismCheck === "Done" &&
                preStepData.status === "approved" && (
                  <Link to={{
                    pathname: `/manage-payment/${articleID}`,
                  }} > <button
                    className="button button-primary"
                    type="button"
                    onClick={() => setfinalSubmitPupup(true)}
                  >
                    PUBLISH NOW
                  </button> </Link>
                )}
            </div>
          </div>
        )}
        
      </div>

      <Modal
        show={showResult}
        size="lg"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogclassName="modal-dialog-centered"
      >
        <Modal.Header>
          <h5 className="modal-title pr-color">Plagarism Report</h5>
          <button
            type="button"
            className="btn-close"
            onClick={(e) => setShowResult(false)}
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                style={{
                  border: 0,
                  width: "100%",
                  height: "500px",
                  overflow: "auto",
                }}
                src={unicheckData.url}
              ></iframe>
            </div>
          </div>
          
        </Modal.Body>
      </Modal>

      <Modal
        show={saveArticleConfirmation}
        size="md"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogclassName="modal-dialog-centered authorModal"
        className="authorModal md-x"
        centered
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title pl-0 pr-0">
            NEXT STEPS OF ARTICLE SUBMISSION
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setsaveArticleConfirmation(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">


          <div className="submissionPopup">
            <strong>STEP 1: Profanity Check</strong>
            <strong>STEP 2: Similarity Index Check</strong>
            <p>
              After your article has cleared both checks,<br />it will be sent for editorial assessment including<br />peer review of your choice.
            </p>
            <p>
              Please also note that you will not be able to edit the article while the checks are underway.
            </p>
            <p>
              Would you like to proceed with the submission?
            </p>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <span
            className="btn-link-active mr-3"
            onClick={(e) => setsaveArticleConfirmation(false)}
          >
            GO BACK
          </span>
          <button
            className="button button-primary ml-3"
            onClick={() => confirmToSubmitArticle()}
            type="button"
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={saveArticleConfirmationNew}
        size="md"
        id="unicheck-results__modal2"
        tabIndex={-1}
        dialogclassName="modal-dialog-centered authorModal"
        className="authorModal md-x"
        centered
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title pl-0 pr-0">
            NEXT STEPS OF ARTICLE SUBMISSION 
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setsaveArticleConfirmationNew(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">


          <div className="submissionPopup">
            
            <strong>This article send to admin </strong>
            <strong>STEP 1: Profanity Check</strong>
            <strong>STEP 2: Similarity Index Check</strong>
            <p>
              After your article has cleared both checks,<br />it will be sent for editorial assessment including<br />peer review of your choice.
            </p>
            <p>
              Please also note that you will not be able to edit the article while the checks are underway.
            </p>
            <p>
              Would you like to proceed with the submission?
            </p>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <span
            className="btn-link-active mr-3"
            onClick={(e) => setsaveArticleConfirmationNew(false)}
          >
            GO BACK
          </span>
          <button
            className="button button-primary ml-3"
            onClick={() => confirmToSubmitArticle()}
            type="button"
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelArticle}
        size="sm"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogclassName="modal-dialog-centered authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">
            Are you sure you would like to cancel?
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setCancelArticle(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="grantDetails pl-2 pr-2 mb-3">
            <div className="row text-center">
              <p className="mb-3">The article will be not be saved. </p>
              <p>
                This could take some time. Please come back in some time and
                access the Similarity Index Report in your Drafts section.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <span
            className="btn-link-active"
            onClick={(e) => setCancelArticle(false)}
          >
            No, GO BACK
          </span>
          <button
            className="button button-primary"
            type="button"
            onClick={(e) => handleRedirect(e, "/myArticle/draft")}
          >
            YES, CANCEL
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName=" "
        show={savedDraftArticle}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title pl-0 pr-0">
            Similarity index check in progress
          </h5>
          
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="similarity pl-2 pr-2 mb-3">
            <div className="similarityText">
              <p>
                We will notify you via email when your article has
                <br />
                cleared this stage.
              </p>
              <p>
                You can also view your report in the{" "}
                <Link to={{ pathname: "/myArticle/draft" }} state={"draft"}>
                  <u>Drafts</u>
                </Link>{" "}
                section{" "}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary"
            onClick={(e) => handleRedirect(e, "/dashboard")}
          >
            My dashboard
          </button>
        </Modal.Footer>
      </Modal>

      {/* Profanity Alert */}
      <Modal
        dialogclassName=""
        show={profanityAlert.isOPen}
        id="congratsMobile"
        tabIndex={-1}
        className={
          profanityAlert.type === 0
            ? "authorModal md-x"
            : "authorModal rightSide profanity-content-right"
        }
        centered
      >
      
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            <img src={alertSearch} alt="#" />
          </div>
          <h5 className="modal-title">PROFANITY check Results</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0 pl-2 pr-2">
          <div className="grantDetails alertPopup">
            <div className="checkResultText">
              <strong>
                Thank you for sharing your research article for submission.{" "}
              </strong>
              <p>
                Before your manuscript is processed, please make sure that the
                following words are removed from your article:
              </p>
              
            </div>

            <div className="offensiveBox">
              <ol>
                {profanityWordsFound.map((wrd, index) => (
                  <li>{wrd}</li>
                ))}
              </ol>
            </div>
            <div className="mt-4 mb-3">
              {profanityAlert.type === 0 && (
                <button
                  className="button button-primary"
                  type="button"
                  onClick={(e) => setprofanityAlert({ isOPen: false, type: 0 })}
                >
                  make Amendments
                </button>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-0">
          

          <div className="helpPopupText pb-3">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>

      <GrammarSpellModal 
        spellCheckModal={spellCheckModal}
        setSpellCheckModal={setSpellCheckModal}
        mainContent={"Thank you for sharing your research article for submission."}
        upperContent={"We have noticed that there are still some grammar and language issues that need your attention."}
        middleContent={"You will find them highlighted in the article for correction."}
        lowerContent={"Before your manuscript is processed, please make sure that the highlighted issues are fixed."}
      />

      {/*Article Word Limit */}
      <Modal
        dialogclassName="modal-dialog-centered modal-md "
        show={plagiarismAlert}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal authorModalRed md-x"
      >
       
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title mt-0">ATTENTION</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setPlagiarismAlert(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="attentionText">
            <p>
              The article has exceeded the word count
              <br />
              limit of 10,000.
            </p>
            <p>
              Please revise your article to bring it below
              <br />
              the word count limit.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="pb-3">
          <button
            className="button button-primary redBtn"
            type="button"
            onClick={() => setPlagiarismAlert(false)}
          >
            OKAY
          </button>

          <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName=" modal-sm modal-dialog  "
        size="md"
        show={savedDetailsPupup}   
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">Your work has been saved</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setsavedDetailsPupup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="beensave">
            <p className="mb-3">
              Find your submission details in the drafts section of your
              dashboard.
            </p>
            
            <p>
              You can continue submission of your article as <br />per your
              convenience.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active mr-3"
            onClick={(e) => handleRedirect(e, "/dashboard")}
          >
            MY DASHBOARD
          </span>
          <button
            className="button button-primary sm ml-3"
            onClick={(e) => setsavedDetailsPupup(false)}
          >
            CONTINUE
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogclassName=""
        show={invalidFilePopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal authorModalRed md-x"
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title">Invalid file</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setinvalidFilePopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="grantDetails alertPopup">
            <div className="invalidText">
              You can only upload MS Word, Excel, PPT,<br />Data and RTF files in this section.
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            className="button button-primary redBtn"
            type="button"
            onClick={() => setinvalidFilePopup(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={finalSubmitPupup}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x authorModal"
        centered
      >
        <Modal.Header className="modal-header">
          
          <h5 className="modal-title mb-0">congratulations!</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setfinalSubmitPupup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0">
        {isUpdateNewVersion===false &&<div className="congratulationsPopup">
            <h3>All checks are now complete</h3>
            <p>
              You can now perform two actions:
              <br />
              1. Preview article 
              <br />
              2. Submit Article
            </p>
            <p>
              Once you submit your article, it will move towards editorial
              assessment based on your choice of review.
            </p>
            <p>
              {" "}
              The estimated time to complete the assessment and reach a decision
              is <u>thirty working days</u>.
            </p>
            <p>
              <i>
                Please note that you will not be able to edit the article while
                the editorial assessment is underway.
              </i>
            </p>
            <p>
              You will receive an email once the assessment result is available.
              You can also check for status updates in the{" "}
              <Link
                to={{ pathname: "/myArticle/submit" }}
                state={"submit"}>
                <u>Under Review</u>
              </Link> section on your dashboard.
            </p>
          </div>}
          {isUpdateNewVersion===true &&<div className="congratulationsPopup">
            <h3>All checks are now complete</h3>
            <p>
              You can now perform two actions:
              <br />
              1. Preview article
              <br />
              2. Submit Article
            </p>
            <p>
              Once you submit your article, it will be reviewed assessed by a Publishing Editor and available for <b>Post Open Peer Review</b>.
            </p>
            <p>
              {" "}
              The estimated time to complete the assessment and reach a decision
              is <u>thirty working days</u>.
            </p>
            <p>
              
                Please check your inbox for more details.
              
            </p>
            
          </div>}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active mr-3"
            onClick={(e) => {
              setfinalSubmitPupup(false);
              previewArticle();
            }}
          >
            preview article
          </span>
          <button
            className="button button-primary ml-3"
            onClick={() => finalSubmitNow()}
          >
            SUBMIT article
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName=""
        show={finalSubmitConfirmPupup}
       //show={true}
        id="congratsMobile"
        tabIndex={-1}
        centered
        //className="authorModal md-640"
        className="authorModal md-x"
      >
         <Confetti width={"450px"} height={"600px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <h5 className="modal-title">
            {preStepData.reviewType?.typeOfReview ===
              "pre-publication peer review only"
              ? "PRE-PUBLISHING DOUBLE ANONYMOUS PEER REVIEW"
              : "POST PUBLISHING OPEN PEER REVIEW"}
          </h5>
          
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
        {preStepData.reviewType?.typeOfReview !==
              "pre-publication peer review only" && <div className="preeReviewPopup">
            <b className="congText">congratulations</b>
            <h3 className="mb-3 d-block">Your article is now submitted for <br/>Editorial Assessment</h3>
            <p>You should receive notification of the decision to your registered email address within <u>three weeks</u>.</p>
            <p>If your manuscript is accepted by the Publishing Editor, it will be published on the platform within <u>72 working hours</u> after making the final payment.</p>
            <p>
            Check for status updates in the <Link
                to={{ pathname: "/myArticle/submit" }}
                state={"submit"}>
                <u>Under Review</u>
              </Link> section on your dashboard.
            </p>
          </div>}

          {preStepData.reviewType?.typeOfReview ===
              "pre-publication peer review only" && <div className="preeReviewPopup postReviewPopup">
            <b className="congText">congratulations</b>
            <h3 className="mb-3 d-block">Your article is now submitted for <br/> Editorial Assessment</h3>
            <p>Typically, the editorial assessment by the Publishing Editor will be completed within three weeks. Post this, the Peer Review Process will take an additional three weeks. </p>
            <p>At every stage, we will update you on the status of your article. </p>
            <p>Should your article be approved for publishing, it will be published on our platform within <u>72 working hours</u>, subject to the payment of the balance Article Processing Charge.</p>
            <p>You can also check the status updates in the <Link to={{ pathname: "/myArticle/submit" }} state={"submit"} className="d-inline-block text-decoration-underline">Under Review</Link> section of your dashboard.</p>
           </div>}
        </Modal.Body>

        <Modal.Footer className="pb-2">
          
          <button
            className="button button-primary"
            onClick={() => {
              appointArticleStatus === "First Revision" ? (
                <Link
                  to={{ pathname: "/myArticle/submit" }}
                  state={"submit"}>
                </Link>
              ) :
              setfinalSubmitConfirmPupup(false);const f=feedbackPupup;f.openPopup=true
              setfeedbackPupup({...f})}}
          >
            OKAY
          </button>
          <div className="helpPopupText mt-4">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogclassName=""
        show={profanitySuccess}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title pl-0 pr-0">
            Thank you for submitting THE article
          </h5>
          
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="profanityTeatPopup">
            <strong>
              The PROFANITY CHECK is <img src={rightChk} />
            </strong>
            <p>As the next step, we will now run the manuscript through a Similarity Index check. To cross this stage, your manuscript should have a similarity index of <b>not more than 15%</b><div className="customTool iconManage">
             
              {["bottom"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Popover>
                      <Popover.Body>
                        <div className="popoverToolWrap">
                          <p>It is important to note that plagiarism is not just about the percentage of content copied; it also involves the manner in which the material is used, cited, and referenced. Similarity indices are not the only indicator of plagiarism.</p><p className="mt-3 mb-0"> Even if the similarity index is within an acceptable range, if
                            the language, structure, and ideas in the research article are too similar to another work, it could still be considered plagiarism.</p>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <img
                    className="pe-cursor ps-1"
                    src={active_info}
                    alt="no img"
                  />
                </OverlayTrigger>
              ))}
             
            </div>
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="button button-primary"
            onClick={() => manageProfanitySuccessPopup()}
          >
            RUN THE CHECK NOW
          </button>
        </Modal.Footer>
      </Modal>
      

      <Modal
        size="md"
        show={citationPopup}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal md-x"
        centered
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title mb-0">Create Citation</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setcitationPopup(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className=" pb-0">
          <div className="citationPopup ">
            <div className="isMultiAuthor">
              <h2>Author Name<sup className="supStar">*</sup></h2>
              <label className="customCheck">
              <Checkbox
                onRadioChange={handleMultiAuthorCheckbox}
                name="isMultiAuthor"
                checked={citationDetails?.isMulti}
                className="multiAuthorCheckbox"
              />
                
                <i></i>
                <b>Article has multiple authors</b>
              </label>
            </div>

            <div className="authorsDetail">
              {citationDetails.totalLength.map((total,index) => (
                <div className= {index===0?'fnamelname':'fnamelname repeatAuthor'}>
                  <div className="fieldWrap">
                    <label className="labelForm">First Name<sup className="supStar">*</sup></label>
                    <Input
                      errors={citationDetails?.fnameError[index]}
                      name={`fname${index}`}
                      handleChange={(e) =>
                        handleCitationInput(e,index, "fname")
                      }
                      className="fieldForm"
                      placeholder="Enter"
                      type="text"
                      value={citationDetails?.fname[index].val}
                    />
                   
                  </div>
                  <div className="fieldWrap">
                    <label className="labelForm">Last Name<sup className="supStar">*</sup></label>
                    <div className="repetField">
                    <Input
                      errors={citationDetails?.lnameError[index]}
                      name={`fname${index}`}
                      handleChange={(e) =>
                        handleCitationInput(e,index, "lname")
                      }
                      className="fieldForm"
                      placeholder="Enter"
                      type="text"
                      label=''
                      value={citationDetails?.lname[index].val}
                    />
                    </div>
                    {index>0 && <b class="closeAuthor"  onClick={() => removeCitationAuthor(index)}></b>}
                    
                  </div>
                </div>
               ))}
              {citationDetails.isMulti===true &&  <div className="button-link addCoAuthor"  onClick={handleMultiCitation}>Add co-Author</div>}
            </div>
            <div className="creationUrl">
              <div className="fieldWrap">
                <label className="labelForm">Article Title<sup className="supStar">*</sup></label>
                <Input
                  errors={citationDetails?.artTitleError}
                  name='artTitle'
                  handleChange={(e) =>
                    handleCitationInput(e,0, "artTitle")
                  }
                  className="fieldForm _se_link_url"
                  placeholder="Enter"
                  type="text"
                  value={citationDetails?.artTitle}
                />
               
              </div>
            </div>
            <div className="creation-publication">
              <div className="fieldWrap">
                <label className="labelForm">Year of Publication<sup className="supStar">*</sup></label>
                <Input
                  errors={citationDetails?.yearError}
                  name='year'
                  handleChange={(e) =>
                    handleCitationInput(e,0, "year")
                  }
                  className="fieldForm"
                  placeholder="Enter"
                  type="text"
                  value={citationDetails?.year}
                />
                
              </div>
            </div>
            <div className="creationUrl">
              <div className="fieldWrap">
                <label className="labelForm">URL<sup className="supStar">*</sup></label>
                <Input
                  errors={citationDetails?.urlError}
                  name='url'
                  handleChange={(e) =>
                    handleCitationInput(e,0, "url")
                  }
                  className="fieldForm _se_link_url"
                  placeholder="Enter"
                  type="text"
                  value={citationDetails?.url}
                />
               
              </div></div>

            
        </div>
        </Modal.Body>

        <Modal.Footer>
          <button
              
              className="button button-primary mb-3"
              onClick={() => genrateCitaion()}
            >
              
              Generate
            </button>

            <div class="guidelinesWrap statementsGuide pl-0 pr-0">
              
                    <span>
                      <sup class="supStar">*</sup>Mandatory</span></div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={citationDetails.publishPopup}
        id="congratsMobile"
        tabIndex={-1}
        className=" authorModal widthAuto"
        centered
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title mb-0">Citation Generated</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => closeCitationPublish()}
          ></button>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="citationPopup">
            
          <div class="finalData mb-3">
      <label class="labelForm d-block mb-2">In-text Citation</label>
      <div class="copyInput">
        { citationDetails.lname.length===1 &&<div className="finalInputWrap">{`(${citationDetails.lname.map(x => x.val).join(", ")}, ${citationDetails.year})`}</div>}
        { citationDetails.lname.length===2 &&<div className="finalInputWrap">{`(${citationDetails.lname.map(x => x.val).join(", ")}, ${citationDetails.year})`}</div>}
        { citationDetails.lname.length>2 && <div className="finalInputWrap">{`(${citationDetails.lname[0].val},${citationDetails.lname[1].val} ${citationDetails.year}), et al.`}</div>}
        <b onClick={() => copyCitationDetails()}  >Copy</b></div>
      </div>
              
        </div>
        </Modal.Body>

        <Modal.Footer>
          <button
              className="button button-primary"
            onClick={() => closeCitationPublish()}  
            >
            Done
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName=""
        show={feedbackPupup.openPopup}
        size="lg"
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal feedBack"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title pl-0 pr-0">
            <div className="feedHead">
              <span><img src={feedWant} /></span>
              <b>HOW WAS YOUR<br/>ARTICLE SUBMISSION JOURNEY?</b> 
            </div>
          
          </h5>
          
        </Modal.Header>
        <Modal.Body className="pt-0 pb-2">
          <div className="feedBackPopup">
            <div className="feedStar">
              <StarRating getRatingValue={getRatingValue}/>
              {feedbackPupup.rateError !== "" && (
                <div className="label-error">
                  {feedbackPupup.rateError}
                </div>
              )}
            </div>
            <p className="feedText">On a scale of 1 to 5, 1 is "Difficult" and 5 is "Smooth Sailing”</p>
             <p>Additionally, we would greatly appreciate any specific feedback you can provide. Your feedback will help us create a seamless experience for all researchers.</p>
            <div className="feedInput"> 
            <Textarea
                  name="feedback"
                  rows="5"
                  className=""
                  placeholder="Enter feedback here..."
                  value={feedbackPupup?.feedback}
                  onChange={(e) => { 
                    const f=feedbackPupup;f.feedback=e.target.value
                    setfeedbackPupup({...f})
                  }}
                />
              
            </div>
            <div className="feedBtn">
            <Link
              className=""
              to={{ pathname: "/myArticle/submit" }}
              state={"submit"}
            >
              <span>Not now</span>
            </Link>
              
              <button onClick={()=>saveFeedBackDetails()}>Send Feedback</button>
            </div>
            <div className="helpPopupText mt-4 mb-2">
            Need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
            </div>
          </div>
 
        </Modal.Body>
 
      </Modal>
    
      <Modal
        dialogclassName=" modal-md modal-dialog  "
        size="md"
       show={feedbackPupup.successPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      ><Confetti width={"450px"} height={"460px"} numberOfPieces={100} />
        <Modal.Header className="modal-header pb-1">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">Thank you for your feedback</h5>
         
          
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">

        <div className="congratulationsPopup text-center">
        
        {
          user?.nationality === "outSideIndia" ? (
            <p className="text-left pl-2 ml-2">It is an integral part of our platform, and we are honoured to be a part of your scholarly journey.</p>
          ) : (
            <>
              <p className="text-left pl-2 ml-2">It is an integral part of our platform, and we are honoured to be a part of your scholarly journey.</p>
              <div className="earnCoinWrap"><b>REFER and EARN</b><span><img src={coins} /></span></div>
              <p className="text-left pl-2 ml-2 mb-0"> Refer 5 colleagues to the Aakashganga platform, and earn 250 Mudras (INR) in your Mudra Kosh (Wallet).</p>
              <p className="text-left pl-2 ml-2 mt-2 mb-4">You can use the mudras to write a new article or update an existing one.</p>
            </>
          )
        }
            
          </div>
 
        </Modal.Body>

        <Modal.Footer className="pb-2">
          
        <Link
          className="button-link mr-3"
          to={{ pathname: "/myArticle/submit" }}
          state={"submit"}
        >
          My dashboard
        </Link>
        {
          user?.nationality === "outSideIndia" ? null : (
            <Link
              className="button button-primary sm ml-3"
              onClick={() => {
                setfeedbackPupup({ successPopup: false })
                setShowReferAndEarn(true);
              }}
            >
              Refer and Earn
            </Link>
          )
        }
       
        <div className="helpPopupText mt-4 paymentTile">
        <sup className="sub">**</sup><span>Payment of 250 Mudras is only applicable in India.</span>
          </div>
          </Modal.Footer>
        </Modal>

        <Modal 
         show={showReferAndEarn}          
        id="referModal" tabIndex={-1}
        className="md-x authorReferPopup"
        keyboard={false}
        backdrop="static"
        >
          <div>
            <div className="modal-content border-0 referContent">
              <div className="modal-header pb-0">
                <h5 className="modal-title referText">Refer and Earn</h5>
                <p className="bigSubText">Invite 5 friends to register</p>
                <div className="mudraCount">
                  
                    <span>
                      <b>Earn</b>
                      <br />
                      250 Mudras
                    </span>
                    <i>
                      <img src={kalashIcon} alt="#" />
                    </i>
                   
                </div>
                 
              </div>
              <div className="modal-body pt-0 pb-0">
                <div className="px-2 text-center shareModal">
                  <div className="referStep">
                    <ul>
                      <li>
                        <span>
                          <b className="icon-persons" />
                        </span>
                        Invite 5 friends to register
                      </li>
                      <li>
                        <span>
                          <b className="icon-register" />
                        </span>
                        Your friends register
                      </li>
                      <li>
                        <span>
                          <b className="icon-wallet" />
                        </span>
                        You receive 250 Mudras{" "}
                      </li>
                    </ul>
                  </div>
                  <p className="receiveText">Your friends also receive 250 Mudras<br/>each as joining bonus after registration.</p>
                  <div className="socialShare">
                    <small>Invite via</small>
                    <ul className="socialRefer">
                      <li>
                        <EmailShareButton
                          separator=" "
                          subject="Your friend has invited you to join Mission Aakashganga"
                          body={title}
                          url=" "
                          shareUrl={title}
                        >
                          <img src={email48} alt="#" />
                        </EmailShareButton>
                      </li>

                      <li>
                        <WhatsappShareButton
                          url={shareUrl}
                          title={title}
                          separator=" "
                          className="Demo__some-network__share-button"
                        >
                          <img src={whatsapp48} alt="#" />
                        </WhatsappShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={shareUrl}
                          title={title}
                          separator=" "
                          className="Demo__some-network__share-button"
                        >
                          <img src={twitter48} alt="#" />
                        </TwitterShareButton>
                      </li>
                    </ul>
                  </div>
                  <div className="divideLine">
                    <b>or</b>
                  </div>
                  <div className="codeBelow ">
                    <small>Refer using your unique code</small>
                    <div className="codeWrap">
                      <p id="#code" className="pr-color flex-fill">
                        {code}
                      </p>
                      <p
                        onClick={(e) => handleCodeCopy(e)}
                        className="sr-color"
                      >
                        <b id="code" className="icon-copy sr-color" />
                        &nbsp;&nbsp;Copy Code
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer pt-0 pb-0">
                <a
                  className="link-gray mr-5"
                  onClick={(e) => handleRedirect(e, '/dashboard')}
                >
                  NOT NOW
                </a>
                <button
                  className="button button-primary ml-3"
                  onClick={(e) => handleRedirect(e, '/dashboard')}
                >
                  MY DASHBOARD
                </button>
                <div class="questionBox reddot">
                  <span>Receiving 250 Mudras is conditional to 5 individuals registering with us using your referral code</span>
                  <span>You will be able to check your reward in the “Mudrakosh” section</span>
                  </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
        dialogclassName="" 
        show={articleWordLimit.openPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal authorModalRed md-x"
      >
        
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={alertSvg} alt="#" />
          </div>
          <h5 className="modal-title">ATTENTION</h5>
          <h5 className="wordCount">{articleWordLimit.isIncrease===true?'Maximum Word Count Exceeded':'Minimum Word Count Requirement Not Met'}</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => {const f=articleWordLimit;f.openPopup=false
              setarticleWordLimit({...f})}}
          ></button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="attentionPopup">
        {articleWordLimit.isIncrease===true && <>
            <p>
            The research article you are submitting has exceeded the maximum word limit of 10,000 words imposed by our publication guidelines.
           </p>
           <p>
           Please adhere to the word limit to ensure consistency and revise your article to meet the specified limit.
           </p>
           <p>
           If you believe your research requires additional words to convey its significance effectively, we encourage you to list your reason in the box below.
           </p>
          </>}
          {articleWordLimit.isDecrease===true && <>
            <p>
            Please note that we have set a minimum word count requirement of 8000 words for research articles.
            </p>
          <p>
             We do this to ensure the comprehensive coverage of research topics and maintain the integrity of our journal.
           </p>
           <p>
           However, if you believe that your research falls into a category where a lower word count would be more appropriate, we kindly request you to list your reason in the box below.
           </p></>}
           <Textarea
                name="limit"
                rows="3"
                className=""
                placeholder="Enter reason here..."
                value={articleWordLimit?.detail}
                onChange={(e) => { 
                  const f=articleWordLimit;f.detail=e.target.value;f.detailError=''
                  setarticleWordLimit({...f})
                }}
              />
               {articleWordLimit.detailError !== "" && (
                <div className="label-error">
                  {articleWordLimit.detailError}
                </div>
              )}
          
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer pb-2">
        <span
            className="button-link-gray text-transform-uppercase mr-3"
            onClick={(e) => { 
              const f=articleWordLimit;f.openPopup=false
              setarticleWordLimit({...f})
            }}
          >
            Back to the article
          </span>
          <button
            className="button button-primary redBtn"
            type="button"
            onClick={() => sendLimitReason()}
          >
            Okay
          </button>
          <div className="helpPopupText mt-4">
            Still need help? Write to us at<a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogclassName=" modal-sm modal-dialog"
        size="md"
        show={articleWordLimit.reqSuccessPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      ><Confetti width={"450px"} height={"300px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">Thank You</h5>
           <span className="greenTitle">Request sent successfully </span>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="beensave pl-4">
            <p className="mb-3">
            We have received your request and you will receive a response within <u>three working days</u>.
            </p>
            <p>
            Check your inbox for more details.
            </p>
           
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
        <span
            className="btn-link-active mr-3"
            onClick={(e) => { 
              const f=articleWordLimit;f.reqSuccessPopup=false
              setarticleWordLimit({...f})
            }}
          >
            continue
          </span>
        <Link
              className="button button-primary sm ml-3"
              to={{ pathname: "/dashboard" }}
              
            >
          My dashboard
        </Link>
          </Modal.Footer>
        </Modal>
        <Modal
        dialogclassName=" modal-sm modal-dialog"
        size="md"
        show={articleWordLimit.alreadyExistReqPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal md-x"
      ><Confetti width={"450px"} height={"300px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div className="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h5 className="modal-title">Your Request is<br/>already submitted</h5>          
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="beensave pl-4">
            <p className="mb-3">
            We will respond within <u>three working days.</u>
            </p>
            <p>
            If you are checking for an update after three days, we recommend that you check your email for a response.
            </p>
           
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
        
        <button
        className="button button-primary sm "
              onClick={(e) => { 
                const f=articleWordLimit;f.alreadyExistReqPopup=false
                setarticleWordLimit({...f})
              }}
              
            >
          OKAY
        </button>
          </Modal.Footer>
        </Modal>
        <Modal
        dialogclassName=" modal-md modal-dialog"
        size="md"
        show={isUpdateNewVersionPopup}
        id="congratsMobile"
        tabIndex={-1}
        centered
        className="authorModal"
      >
        <Modal.Header className="modal-header">
          
          <h5 className="modal-title p-0">Article submitted for processing</h5>   
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => {setisUpdateNewVersionPopup(false) }}
          ></button>       
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="beensave pl-4">
            <p className="">
            You can also check for status updates in the <Link
                className="btn-link-active text-transform-none"
                to={{ pathname: "/myArticle/submit" }}
                state={"submit"}>
                <u><b>Under Review</b></u>
              </Link> section on your dashboard.
            </p>
            
           
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
        
        <button
          className="button button-primary sm "
          onClick={() => {
            appointArticleStatus === "First Revision" ? (
              <Link
                to={{ pathname: "/myArticle/submit" }}
                state={"submit"}>
              </Link>
            ) :
            setisUpdateNewVersionPopup(false);const f=feedbackPupup;f.openPopup=true
            setfeedbackPupup({...f})
          }}
        >
          OKAY
        </button>
          </Modal.Footer>
        </Modal>
        <Modal
        show={mobileAlert}
        size="md"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered authorModal"
      >
      <Modal.Header className="modal-header pb-0">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => (setMobileAlert(false), handleRedirect(e, "/"))}
        />
        <Modal.Title className="modal-title pl-0">
              <h2 className="fontsizeshastra ml-4">Note</h2>
            </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0 pt-0">
        <div className="grantDetails pl-2 pr-2 mb-3">
          <div className="writeYourContent pb-0 pt-0">
            <p className="mb-0 text-left">
              <p className="yellowTextWelcome text-center blackColorTestRegst">
              For a better publishing experience, article submission is currently supported on desktop only. Please log in again from your desktop.
              </p>
            </p>
          </div>
        </div>
      </Modal.Body>
      
    </Modal>
    </div>
  );
};

export default SubmitArticle;
